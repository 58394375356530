import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import AccountContext from "../contexts/AccountContext";
import AccountInfo from "./AccountInfo";
import AuthContext from "../contexts/AuthContext";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead, TablePagination,
    TableRow,
} from "@material-ui/core";

export default function ClientManagement() {
    const [filters, setFilters] = useState({
        accountName: '',
        companyName: '',
        brandName: '',
        userName: '',
        email: '',
        accountManager: '',
        accountType: '',
        packageCode: '',
        onlyActive: false,
        showBalance: true,
        contractEndDate: '',
        monthlyPayment: false,
        frontPayment: false
    })

    const [currentPage, setCurrentPage] = useState(0);
    const [entryCount, setEntryCount] = useState(10);

    const {getAccounts, accounts} = useContext(AccountContext);
    const {myraUser, parameters} = useContext(AuthContext);

    async function displayAccounts(event) {
        event.preventDefault();
        await getAccounts(filters);
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setEntryCount(event.target.value)
        setCurrentPage(0);
    };

    return (<>
            <div className="tabs">
                <Link to="client-management" className="tab">Client Management</Link>
                <Link to="brands-query" className="tab">Brands Query</Link>
            </div>
            <div className="clearfix"/>
            <div className="create-client">
                <button><Link to="new-account"><i className="fas fa-plus-circle"/>Create Client</Link>
                </button>
            </div>
            <form onSubmit={displayAccounts}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <div className="input">
                            <span>Account Name</span>
                            <input type="text" name="accountName"
                                   onChange={e => setFilters({...filters, accountName: e.target.value})}
                                   value={filters.accountName}/>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="input">
                            <span>Company Name</span>
                            <input type="text" name="companyName"
                                   onChange={e => setFilters({...filters, companyName: e.target.value})}
                                   value={filters.companyName}/>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="input">
                            <span>Brand Name</span>
                            <input type="text" name="brandName"
                                   onChange={e => setFilters({...filters, brandName: e.target.value})}
                                   value={filters.brandName}/>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="input">
                            <span>User Name</span>
                            <input type="text" name="userName"
                                   onChange={e => setFilters({...filters, name: e.target.value})}
                                   value={filters.name}/>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="input">
                            <span>User E-Mail</span>
                            <input type="text" name="email"
                                   onChange={e => setFilters({...filters, email: e.target.value})}
                                   value={filters.email}/>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <select style={{width: "100%", paddingLeft: 20}} value={filters.accountManager}
                                onChange={e => setFilters({...filters, accountManager: e.target.value})}>
                            <option disabled value=''>Select Account Manager</option>
                            {
                                parameters['managers'].map((manager) => manager.region === myraUser.region ? (
                                    <option key={manager.id} value={manager.id}>{manager['fullname']}</option>) : ''
                                )
                            }
                            <option value=''>None</option>
                        </select>
                        <i className="fas fa-angle-down"/>
                    </Grid>

                    <Grid item style={{height: 60}} xs={12}/>

                    <Grid item xs={4}>
                        <select style={{width: "100%", paddingLeft: 20}} key={filters.type} value={filters.type}
                                onChange={e => setFilters({...filters, type: e.target.value})}>
                            <option disabled value=''>Select Account Type</option>
                            {
                                parameters['accountAndPackageTypes'].map((accountType) => (
                                    <option key={accountType.type}
                                            value={accountType.type}>{accountType['def']}</option>
                                ))
                            }
                            <option value=''>None</option>
                        </select>
                        <i className="fas fa-angle-down"/>
                    </Grid>
                    <Grid item xs={4}>
                        <select style={{width: "100%", paddingLeft: 20}} value={filters.packageCode}
                                onChange={e => setFilters({...filters, packageCode: e.target.value})}>
                            <option disabled value=''>Select Package Type</option>
                            {
                                parameters['accountAndPackageTypes'].map((account) =>
                                    account.type === filters.type ? (
                                        account['packages'].map((pkg) => (
                                            <option key={pkg.type} value={pkg.type}>{pkg.name}</option>
                                        ))
                                    ) : "")

                            }
                        </select>
                        <i className="fas fa-angle-down"/>
                    </Grid>
                    <Grid item xs={4} pt={15}>
                        <div style={{display: 'inline-block', padding: 15}}>
                            <input type="checkbox" name="onlyActive"
                                   onChange={e => setFilters({...filters, onlyActive: e.target.checked})}
                                   value={filters.onlyActive}/>
                            <span>Only Active</span>
                        </div>
                        <div style={{display: 'inline-block'}}>
                            <input type="checkbox" name="showBalance"
                                   onChange={e => setFilters({...filters, showBalance: e.target.checked})}
                                   value={filters.showBalance}/>
                            <span>Show Balance</span>
                        </div>
                    </Grid>

                    <Grid item xs={4}>
                        <div className="input date">
                            <i className="fas fa-calendar"/>
                            <span>Contract End Date</span>
                            <input type="date" name="contractEndDate"
                                   onChange={e => setFilters({...filters, contractEndDate: e.target.value})}
                                   value={filters.contractEndDate}/>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <span>Payment</span>
                        <div style={{display: 'inline-block', padding: 15}}>
                            <input type="checkbox" name="monthlyPayment"
                                   onChange={e => setFilters({
                                       ...filters,
                                       monthlyPayment: e.target.checked
                                   })}
                                   value={filters['paymentTypes']}/>
                            <span>Monthly</span>
                            <input type="checkbox" name="frontPayment"
                                   onChange={e => setFilters({
                                       ...filters,
                                       frontPayment: e.target.checked
                                   })}
                                   value={filters['paymentTypes']}/>
                            <span>Front Payment</span>
                        </div>
                    </Grid>

                    <Grid item xs={4}/>

                    <Grid item xs={9}/>
                    <Grid item xs={3}>
                        <button className="button m-t-40">DISPLAY</button>
                    </Grid>

                    <div className="clearfix"/>
                    <Grid item style={{height: 60}} xs={12}/>
                </Grid>
            </form>

            <TableContainer>
                <Table className="data-table dataTable no-footer">
                    <TableHead>
                        <TableRow>
                            <TableCell>Account Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Package</TableCell>
                            <TableCell>Payment</TableCell>
                            <TableCell>Account Manager</TableCell>
                            <TableCell>Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            accounts ?
                                (entryCount > 0
                                    ? accounts.slice(currentPage * entryCount, currentPage * entryCount + entryCount) : accounts).map((
                                    account => <AccountInfo key={account.id} value={account}/>))
                                : ""}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {accounts.length <= entryCount * (currentPage + 1)}
                            <TablePagination count={accounts.length === -1 ? 0 : accounts.length}
                                             onPageChange={handleChangePage}
                                             page={currentPage}
                                             rowsPerPage={entryCount}
                                             onRowsPerPageChange={handleChangeRowsPerPage}/>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}
