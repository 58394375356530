import Main from "./components/Main";
import Loader from "./components/Loader";


function App() {
    return (
        <>
            <Loader/>
            <Main/>
        </>
    )
}

export default App;
