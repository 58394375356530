import React, {useState, useContext} from 'react';
import AuthContext from "../contexts/AuthContext";
import {Link, useHistory} from "react-router-dom";
import {Grid} from "@material-ui/core";

function Login() {
    const [details, setDetails] = useState({username: '', password: ''})
    const {login, getMyraUsers, getParameters} = useContext(AuthContext);
    let history = useHistory();

    async function handleSubmit(event) {
        event.preventDefault();
        await login(details);
        await getMyraUsers();
        await getParameters();
        history.push('/');
    }

    return (
        <div>
            <div style={{width: "auto", position: 'absolute', left: '30%', top: '60%'}}
                 className="border p-30 p-t-60 p-b-40">
                <div className="title">LOGIN</div>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='input'>
                                <span>Username (E-mail) </span>
                                <input name='username'
                                       onChange={e => setDetails({...details, username: e.target.value})}
                                       value={details.username}/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='input'>
                                <span>Password </span>
                                <input type='password' name='password'
                                       onChange={e => setDetails({...details, password: e.target.value})}
                                       value={details.password}/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <button className='button' type='submit'>Login</button>
                        </Grid>
                    </Grid>
                </form>
                <div style={{paddingTop: 30}}>
                    <Link to="password-reset">Forgot password?</Link>
                </div>
            </div>
        </div>
    )
}

export default Login
