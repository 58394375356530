import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Switch,
    withStyles
} from '@material-ui/core'
import AuthContext from '../contexts/AuthContext'
import React, {useContext, useEffect, useState} from 'react'
import AccountContext from '../contexts/AccountContext'
import {useParams} from 'react-router-dom'
import AccountMenu from '../components/AccountMenu'

const GreenSwitch = withStyles({
    switchBase: {
        color: '#ff0000',
        '&$checked': {
            color: '#4ADE79',
        },
        '&$checked + $track': {
            backgroundColor: '#4ADE79',
        },
    },
    checked: {},
    track: {
        backgroundColor: '#ff0000'
    },
})(Switch)

function Details() {
    const {myraUser, parameters} = useContext(AuthContext)
    const {account, updateAccount} = useContext(AccountContext)

    const currencies = ['USD', 'EURO', 'TL']
    const invoiceTypes = ['Local', 'Global']
    const pricingModels = {'Fixed': 'Fixed', 'Perbrand': 'Per Brand'}
    const paymentTypes = {'monthly': 'Monthly', 'frontPayment': 'Front Payment'}
    const pricingInitialState = ''
    const pricingObjInitialState = {
        '1': '',
        '4-5': '',
        '6-10': '',
        '11-30': '',
        '31-50': '',
        '51+': '',
    }

    const accountInitialState = {
        'accountName': account.accountName || "",
        'accountManagerId': '',
        'status': true,
        'accountType': '',
        //    contact
        'userName': '',
        'title': '',
        'mobilePhone': '',
        'email': '',
        'webSite': '',
        'address': '',
        'officePhone': '',
        'postalCode': '',
        'state': '',
        'country': '',
        //      agreement/pricing
        'package': '',
        'onlinePayment': false,
        'invoicingPage': false,
        'sendOnlyTest': false,
        'frontPaymentAmount': '',
        'currency': '',
        'invoicing': '',
        'paymentType': '',
        'pricingModel': '',
        'pricing': pricingInitialState,
        'closeAtEndDate': false,
        // legal-tax
        'companyFullName': '',
        'taxOffice': '',
        'taxNo': '',
        'taxPersonEmail': '',
        'invoiceAddress': '',
        'accountingCode': '',
        // accounting contact
        'accountingContactName': '',
        'accountingContactTitle': '',
        'accountingContactMobilePhone': '',
        'accountingContactEmail': '',
        'note': ''
    }

    const [accountObject, setAccountObject] = useState(accountInitialState)
    const {accountId} = useParams()

    useEffect(() => {
        setAccountObject(account)
    }, [account, updateAccount])

    const handleStatusChange = (event) => {
        setAccountObject({...accountObject, [event.target.name]: event.target.checked})
    }

    const handleAccountTypeChange = (event) => {
        setAccountObject({...accountObject, 'accountType': event.target.value, 'package': ''})
    }

    function handlePackageTypeChange(event) {
        setAccountObject({...accountObject, 'package': event.target.value})
    }

    function handleInvoicingChange(event) {
        setAccountObject({...accountObject, 'invoicing': event.target.value})
    }

    function handleCurrencyChange(event) {
        setAccountObject({...accountObject, 'currency': event.target.value})
    }

    function handlePricingModelChange(event) {
        event.target.value === 'Fixed' ? (
            setAccountObject({...accountObject, 'pricingModel': event.target.value, 'pricing': pricingInitialState})
        ) : (event.target.value === 'Perbrand' ? (setAccountObject({
                ...accountObject,
                'pricingModel': event.target.value,
                'pricing': pricingObjInitialState
            })) : (setAccountObject({
                ...accountObject,
                'pricingModel': '',
                'pricing': pricingInitialState
            }))
        )
    }

    let legalTaxData = (<div className="border p-30 p-t-60 p-b-40">
        <div className="title">LEGAL / TAX</div>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Company Full Name</span>
                    <input type="text" name="companyFullName" value={accountObject.companyFullName}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               companyFullName: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Tax Office</span>
                    <input type="text" name="taxOffice" value={accountObject.taxOffice}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               taxOffice: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Tax No</span>
                    <input type="text" name="taxNo" value={accountObject.taxNo}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               taxNo: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>E-mail</span>
                    <input type="text" name="taxPersonEmail" value={accountObject.taxPersonEmail}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               taxPersonEmail: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
                <div className="input">
                    <span>Invoice Address</span>
                    <input type="text" name="invoiceAddress" value={accountObject.invoiceAddress}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               invoiceAddress: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Accounting Code</span>
                    <input type="text" name="accountingCode" value={accountObject.accountingCode}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               accountingCode: e.target.value
                           })}
                    />
                </div>
            </Grid>
        </Grid>
    </div>)

    let accountingContactData = (<div className="border p-30 p-t-60 p-b-40 m-t-40">
        <div className="title">ACCOUNTING CONTACT</div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>Name</span>
                    <input type="text" name="accountingContactName" value={accountObject.accountingContactName}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               accountingContactName: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>Title</span>
                    <input type="text" name="accountingContactTitle" value={accountObject.accountingContactTitle}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               accountingContactTitle: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>Mobile Phone</span>
                    <input type="text" name="accountingContactMobilePhone"
                           value={accountObject.accountingContactMobilePhone}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               accountingContactMobilePhone: e.target.value
                           })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>E-mail</span>
                    <input type="text" name="accountingContactEmail" value={accountObject.accountingContactEmail}
                           onChange={e => setAccountObject({
                               ...accountObject,
                               accountingContactEmail: e.target.value
                           })}
                    />
                </div>
            </Grid>
        </Grid>
    </div>)

    let noteData = (<div className="border p-30 p-t-60 p-b-40 m-t-40">
        <div className="title">NOTE</div>
        <Grid container>
            <Grid item xs={12}>
                <div className="input text">
                                    <textarea
                                        style={{maxHeight: 200}}
                                        value={accountObject.note}
                                        onChange={e => {
                                            setAccountObject({...accountObject, note: e.target.value})
                                        }
                                        }/>
                </div>
            </Grid>
        </Grid>
    </div>)

    let agreement
    let legalTax
    let accountingContact
    let note

    switch (accountObject.accountType) {
        case '0':
            agreement = (<div className="query-form" data-form="agency">
                {/*AGREEMENT PRICING*/}
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">AGREEMENT / PRICING</div>
                        <Grid item xs={12} md={6}>
                            <div className="border m-0 p-15">
                                <FormControl>
                                    <RadioGroup row value={accountObject.package}
                                                onChange={handlePackageTypeChange}>
                                        <FormLabel disabled style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            paddingRight: 15
                                        }}>Package: </FormLabel>
                                        {
                                            parameters.accountAndPackageTypes.map((account) =>
                                                account.type.toString() === accountObject.accountType ? (
                                                    account.packages.map((pkg) => (
                                                        <FormControlLabel key={pkg.type} value={pkg.type}
                                                                          control={<Radio size="small"
                                                                                          color="default"/>}
                                                                          label={pkg.name}/>
                                                    ))
                                                ) : '')
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginBottom: 15}} className="a-right p-t-20">
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="onlinePayment"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           onlinePayment: e.target.checked
                                       })}
                                       value={accountObject.onlinePayment}/>
                                <span>Online Payment</span>
                            </div>
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="invoicingPage"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           invoicingPage: e.target.checked
                                       })}
                                       value={accountObject.invoicingPage}/>
                                <span>Invoicing Page</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="sendOnlyTest"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           sendOnlyTest: e.target.checked
                                       })}
                                       value={accountObject.sendOnlyTest}/>
                                <span>Send Only Test Users</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input">
                                <span>Front Payment Amount</span>
                                <input type="text" name="frontPaymentAmount"
                                       value={accountObject.frontPaymentAmount}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           frontPaymentAmount: e.target.value
                                       })}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={accountObject.currency}
                                                onChange={handleCurrencyChange}>
                                        <FormLabel disabled style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            paddingRight: 15
                                        }}>Currency: </FormLabel>
                                        {
                                            currencies.map((currency) => (
                                                <FormControlLabel value={currency}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={currency}
                                                                  label={currency}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={accountObject.invoicing}
                                                onChange={handleInvoicingChange}>
                                        <FormLabel disabled style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            paddingRight: 15
                                        }}>Invoicing: </FormLabel>
                                        {
                                            invoiceTypes.map((type) => (
                                                <FormControlLabel value={type}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={type}
                                                                  label={type}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup row value={accountObject.pricingModel}
                                            onChange={handlePricingModelChange}>
                                    {
                                        Object.keys(pricingModels).map((key, keyInd) =>
                                            <FormControlLabel style={{paddingRight: 30}}
                                                              value={key}
                                                              control={<Radio size="small"
                                                                              color="default"/>}
                                                              key={key}
                                                              label={pricingModels[key]}/>
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                            {/*    TODO Backendde object eklenecek, data kismi yapilacak. Agreement kismi! */}
                        </Grid>
                        {
                            accountObject.pricingModel === 'Fixed' ? (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <div className="input">
                                            <span>Fixed</span>
                                            <input type="text" name="fixedPayment" value={accountObject.pricing}
                                                   onChange={e => setAccountObject({
                                                       ...accountObject,
                                                       pricing: e.target.value
                                                   })}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={8}/>
                                </>
                            ) : (accountObject.pricingModel === 'Perbrand' ? (
                                    <>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>1</span>
                                                <input type="text" name="1"
                                                       value={accountObject.pricing['1']}
                                                       onChange={e => setAccountObject({
                                                           ...accountObject,
                                                           pricing: {
                                                               ...accountObject.pricing,
                                                               '1': e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>4-5</span>
                                                <input type="text" name="4-5"
                                                       value={accountObject.pricing['4-5']}
                                                       onChange={e => setAccountObject({
                                                           ...accountObject,
                                                           pricing: {
                                                               ...accountObject.pricing,
                                                               '4-5': e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>6-10</span>
                                                <input type="text" name="6-10"
                                                       value={accountObject.pricing['6-10']}
                                                       onChange={e => setAccountObject({
                                                           ...accountObject,
                                                           pricing: {
                                                               ...accountObject.pricing,
                                                               '6-10': e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>11-30</span>
                                                <input type="text" name="11-30"
                                                       value={accountObject.pricing['11-30']}
                                                       onChange={e => setAccountObject({
                                                           ...accountObject,
                                                           pricing: {
                                                               ...accountObject.pricing,
                                                               '11-30': e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>31-50</span>
                                                <input type="text" name="31-50"
                                                       value={accountObject.pricing['31-50']}
                                                       onChange={e => setAccountObject({
                                                           ...accountObject,
                                                           pricing: {
                                                               ...accountObject.pricing,
                                                               '31-50': e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>51+</span>
                                                <input type="text" name="51+"
                                                       value={accountObject.pricing['51+']}
                                                       onChange={e => setAccountObject({
                                                           ...accountObject,
                                                           pricing: {
                                                               ...accountObject.pricing,
                                                               '51+': e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                    </>
                                ) : ''
                            )
                        }
                    </Grid>
                    <div className="col-xs-12 m-t-30 m-b-50">
                        <div className="h-line m-t-30"/>
                    </div>
                    <Grid item xs={12} sm={4} md={3}>
                        <button className="upload-button m-t-40">Upload Local Agreement</button>
                    </Grid>
                </div>
            </div>)
            legalTax = legalTaxData
            accountingContact = null
            note = noteData
            break
        case '1':
            agreement = (<div className="query-form" data-form="corporate">
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">AGREEMENT / PRICING</div>
                        <Grid item xs={12} md={6}>
                            <div className="border m-0 p-15">
                                <FormControl>
                                    <RadioGroup row value={accountObject.package}
                                                onChange={handlePackageTypeChange}>
                                        <FormLabel disabled style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            paddingRight: 15
                                        }}>Package: </FormLabel>
                                        {
                                            parameters.accountAndPackageTypes.map((account) =>
                                                account.type.toString() === accountObject.accountType ? (
                                                    account.packages.map((pkg) => (
                                                        <FormControlLabel key={pkg.type} value={pkg.type}
                                                                          control={<Radio size="small"
                                                                                          color="default"/>}
                                                                          label={pkg.name}/>
                                                    ))
                                                ) : '')
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginBottom: 15}} className="a-right p-t-20">
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="onlinePayment"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           onlinePayment: e.target.checked
                                       })}
                                       value={accountObject.onlinePayment}/>
                                <span>Online Payment</span>
                            </div>
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="invoicingPage"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           invoicingPage: e.target.checked
                                       })}
                                       value={accountObject.invoicingPage}/>
                                <span>Invoicing Page</span>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="sendOnlyTest"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           sendOnlyTest: e.target.checked
                                       })}
                                       value={accountObject.sendOnlyTest}/>
                                <span>Send Only Test Users</span>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="closeAtEndDate"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           closeAtEndDate: e.target.checked
                                       })}
                                       value={accountObject.closeAtEndDate}/>
                                <span>Close at End Date</span>
                            </div>
                        </Grid>
                        <Grid item xs={6}/>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Agreement Start Date</span>
                                <input type="date" name="agreementStartDate"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           agreementStartDate: e.target.value
                                       })}
                                       value={accountObject.agreementStartDate}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Agreement End Date</span>
                                <input type="date" name="agreementEndDate"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           agreementEndDate: e.target.value
                                       })}
                                       value={accountObject.agreementEndDate}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={accountObject.invoicing}
                                                onChange={handleCurrencyChange}>
                                        <FormLabel disabled style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            paddingRight: 15
                                        }}>Invoicing: </FormLabel>
                                        {
                                            invoiceTypes.map((type) => (
                                                <FormControlLabel value={type}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={type}
                                                                  label={type}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className="input">
                                <span>Free Amount</span>
                                <input type="text" name="freeAmount" value={accountObject.freeAmount}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           freeAmount: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={accountObject.currency}
                                                onChange={handleCurrencyChange}>
                                        <FormLabel disabled style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            paddingRight: 15
                                        }}>Currency: </FormLabel>
                                        {
                                            currencies.map((currency) => (
                                                <FormControlLabel value={currency}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={currency}
                                                                  label={currency}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup row value={accountObject.pricingModel}
                                            onChange={handlePricingModelChange}>
                                    {
                                        Object.keys(paymentTypes).map((key, keyInd) =>
                                            <FormControlLabel style={{paddingRight: 30}}
                                                              value={paymentTypes[key]}
                                                              control={<Radio size="small"
                                                                              color="default"/>}
                                                              key={key}
                                                              label={paymentTypes[key]}/>
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                            {/*    TODO Backendde object eklenecek, data kısmı yapılacak. Agreement kısmı! */}
                        </Grid>
                    </Grid>
                    <div className="col-xs-12 m-t-30 m-b-50">
                        <div className="h-line m-t-30"/>
                    </div>
                    <Grid item xs={12} sm={4} md={3}>
                        <button className="upload-button m-t-40">Upload Local Agreement</button>
                    </Grid>
                </div>
            </div>)
            legalTax = legalTaxData
            accountingContact = accountingContactData
            note = noteData
            break
        case '2':
            agreement = (<div className="query-form" data-form="sponsored">
                {/*AGREEMENT PRICING*/}
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">AGREEMENT / PRICING</div>
                        <Grid item xs={12}>
                            <div className="border m-0 p-15">
                                <FormControl>
                                    <RadioGroup row value={accountObject.package}
                                                onChange={handlePackageTypeChange}>
                                        <FormLabel disabled style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            paddingRight: 15
                                        }}>Package: </FormLabel>
                                        {
                                            parameters.accountAndPackageTypes.map((account) =>
                                                account.type.toString() === accountObject.accountType ? (
                                                    account.packages.map((pkg) => (
                                                        <FormControlLabel key={pkg.type} value={pkg.type}
                                                                          control={<Radio size="small"
                                                                                          color="default"/>}
                                                                          label={pkg.name}/>
                                                    ))
                                                ) : '')
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>

                        <Grid item xs={12} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="sendOnlyTest"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           sendOnlyTest: e.target.checked
                                       })}
                                       value={accountObject.sendOnlyTest}/>
                                <span>Send Only Test Users</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Brand Closed Date Start</span>
                                <input type="date" name="brandClosedDateStart"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           brandClosedDateStart: e.target.value
                                       })}
                                       value={accountObject.brandClosedDateStart}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Brand Closed Date End</span>
                                <input type="date" name="brandClosedDateEnd"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           brandClosedDateEnd: e.target.value
                                       })}
                                       value={accountObject.brandClosedDateEnd}/>
                            </div>
                        </Grid>
                        {/*<Grid item xs={12} sm={6} md={4} className="col-xs-12 m-t-30 m-b-30">*/}
                        <Grid item xs={12} sm={6} md={4} style={{marginBottom: 15}} className="p-t-20">
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="passiveBrands"
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           passiveBrands: e.target.checked
                                       })}
                                       value={accountObject.passiveBrands}/>
                                <span>Passive Brands</span>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="col-xs-12 m-t-30 m-b-50">
                        <div className="h-line m-t-30"/>
                    </div>
                    <Grid item xs={12} sm={4} md={3}>
                        <button className="upload-button m-t-40">Upload Local Agreement</button>
                    </Grid>
                </div>
            </div>)
            legalTax = legalTaxData
            accountingContact = accountingContactData
            note = noteData
            break
        default:
            agreement = null
            legalTax = null
            accountingContact = null
            note = null
            break
    }

    async function updateAccountDetails(event) {
        event.preventDefault()
        await updateAccount(accountId, accountObject)
    }

    return (
        <div>
            <AccountMenu/>
            <form onSubmit={updateAccountDetails}>
                {/*Account Details*/}
                <div className="row p-15 m-t-30">
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item xs={12} md={4}>
                            <div className="input">
                                <span>Account Name</span>
                                <input type="text" name="accountName" value={accountObject.accountName}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           accountName: e.target.value
                                       })}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <select style={{width: '100%', paddingLeft: 20}} value={accountObject.accountManagerId}
                                    onChange={e => setAccountObject({
                                        ...accountObject,
                                        accountManagerId: e.target.value
                                    })}>
                                <option disabled value=''>Select Account Manager</option>
                                {
                                    parameters.managers.map((manager) => manager.region === myraUser.region ? (
                                        <option key={manager.id} value={manager.id}>{manager.fullname}</option>) : ''
                                    )
                                }
                                <option value=''>None</option>
                            </select>
                            <i className="fas fa-angle-down"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{marginBottom: 15}} className="a-right p-t-10">
                            Status:
                            <FormControlLabel
                                control={<GreenSwitch checked={accountObject.status} onChange={handleStatusChange}/>}
                                label={<b>{(accountObject.status === true ? 'Active' : 'Inactive')}</b>}
                                name="status"
                                labelPlacement="start">
                            </FormControlLabel>
                        </Grid>
                    </Grid>
                </div>
                {/*Account Type*/}
                <div className="border query-checks p-15 m-t-30 m-b-40">
                    <Grid container>
                        <Grid item>
                            <FormControl>
                                <RadioGroup row value={accountObject.accountType} onChange={handleAccountTypeChange}>
                                    <FormLabel disabled
                                               style={{alignItems: 'center', display: 'flex', paddingRight: 15}}>Account
                                        Type: </FormLabel>
                                    {
                                        parameters.accountAndPackageTypes.map((accType) => (
                                                <FormControlLabel key={accType.type}
                                                                  value={accType.type}
                                                                  control={<Radio size="small" color="default"/>}
                                                                  label={accType.def}/>
                                            )
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                {/*CONTACT*/}
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">CONTACT</div>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>User Name</span>
                                <input type="text" name="userName" value={accountObject.userName}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           userName: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>Title</span>
                                <input type="text" name="title" value={accountObject.title}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           title: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>Mobile Phone</span>
                                <input type="text" name="mobilePhone" value={accountObject.mobilePhone}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           mobilePhone: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>E-Mail</span>
                                <input type="text" name="email" value={accountObject.email}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           email: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className="input">
                                <span>Web Site</span>
                                <input type="text" name="web" value={accountObject.webSite}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           webSite: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <div className="input">
                                <span>Address</span>
                                <input type="text" name="address" value={accountObject.address}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           address: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>Office Phone</span>
                                <input type="text" name="officePhone" value={accountObject.officePhone}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           officePhone: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input" style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                <span>Post Code</span>
                                <input type="text" name="postalCode" value={accountObject.postalCode}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           postalCode: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            {/*TODO ask/add array*/}
                            <div className="input select">
                                <span>State</span>
                                <input type="text" name="state" value={accountObject.state}
                                       onChange={e => setAccountObject({
                                           ...accountObject,
                                           state: e.target.value
                                       })}/>
                                <i className="fas fa-angle-down"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <select style={{width: '100%', paddingLeft: 20}} value={accountObject.country}
                                    onChange={e => setAccountObject({
                                        ...accountObject,
                                        country: e.target.value
                                    })}>
                                <option disabled value=''>Select Country</option>
                                {
                                    Object.keys(parameters.timeZones).map((key, keyInd) =>
                                        <option key={key} value={key}>{parameters.timeZones[key].timezone}</option>
                                    )
                                }
                            </select>
                        </Grid>
                    </Grid>
                </div>
                <div className="query-forms">
                    {agreement}
                </div>
                <div className="query-forms">
                    <div className="query-form" data-form="agency">
                        {/*LEGAL TAX*/}
                        {legalTax}
                        {/*ACCOUNTING CONTACT*/}
                        {accountingContact}
                        {/*NOTE*/}
                        {note}
                    </div>
                </div>

                <div className="clearfix"/>
                <Grid container spacing={2}>
                    <Grid item xs={9}/>
                    <Grid item xs={3} style={{paddingRight: 15}}>
                        <button className="button m-t-40">SAVE AND UPDATE</button>
                    </Grid>
                </Grid>
                <div className="clearfix"/>
            </form>
        </div>
    )
}

export default Details
