import AccountMenu from "../components/AccountMenu";
import React, {useContext, useEffect, useState} from "react";
import AccountContext from "../contexts/AccountContext";
import {useParams} from "react-router-dom";
import AccountBadge from "../components/AccountBadge";
import {
    FormControl,
    FormControlLabel, FormLabel,
    Grid, Radio, RadioGroup, Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, withStyles
} from "@material-ui/core";
import ActivationHistoryInfo from "../components/ActivationHistoryInfo";
import AuthContext from "../contexts/AuthContext";

const GreenSwitch = withStyles({
    switchBase: {
        color: "#ff0000",
        '&$checked': {
            color: "#4ADE79",
        },
        '&$checked + $track': {
            backgroundColor: "#4ADE79",
        },
    },
    checked: {},
    track: {
        backgroundColor: "#ff0000"
    },
})(Switch);

function Status() {
    const {accountId} = useParams();
    const {getAccountStatus, activationHistory, account, postNewAction} = useContext(AccountContext);
    const {parameters, myraUser} = useContext(AuthContext);

    const activationReasons = ['Finance', 'Customer', 'Other'];

    const [currentPage, setCurrentPage] = useState(0);
    const [entryCount, setEntryCount] = useState(10);
    const [actionObj, setActionObj] = useState(
        {
            "action": "",
            "status": "",
            "holdAction": "",
            "actionBy": myraUser['fullname'],
            "autoClosingDate": "",
            "package": account.package,
            "note": "",
            "reason": ""
        }
    );

    const accountTypeObj = parameters['accountAndPackageTypes'].find(({type}) => type === account.accountType)
    const accountPackageObj = accountTypeObj['packages'].find(({type}) => type === 'ax')

    // async function getStatus() {
    //     await getAccountStatus(accountId)
    // }

    const handleStatusChange = (event) => {
        setActionObj({
            ...actionObj,
            [event.target.name]: event.target.checked,
            action: event.target.checked ? "Open" : "Close"
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setEntryCount(event.target.value)
        setCurrentPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        console.log("actionObj", JSON.stringify(actionObj))
    }, [actionObj])

    useEffect(() => {
        getAccountStatus(accountId)
    }, [])


    async function saveAction(event) {
        event.preventDefault();
        await postNewAction(accountId, actionObj);
    }

    function handleReasonChange(event) {
        setActionObj({...actionObj, "reason": event.target.value})
    }

    return (
        <>
            <AccountMenu/>
            <AccountBadge/>
            <h3>Account Activation History</h3>
            <div>
                <TableContainer>
                    <Table className="data-table dataTable no-footer">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Action Owner</TableCell>
                                {/*<TableCell>Person</TableCell>*/}
                                <TableCell>Reason</TableCell>
                                <TableCell>Note</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(
                                entryCount > 0
                                    ? activationHistory.slice(currentPage * entryCount, currentPage * entryCount + entryCount) : activationHistory).map(
                                history => <ActivationHistoryInfo key={history['dateAction']}
                                                                  value={history}/>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                {activationHistory.length <= entryCount * (currentPage + 1)}
                                <TablePagination count={activationHistory.length === -1 ? 0 : activationHistory.length}
                                                 onPageChange={handleChangePage}
                                                 page={currentPage}
                                                 rowsPerPage={entryCount}
                                                 onRowsPerPageChange={handleChangeRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
            <form onSubmit={saveAction}>
                <div className='row m-t-50'>
                    <div className="border" style={{padding: 20}}>
                        <Grid container spacing={2}>
                            <div className="title">NEW ACTION</div>
                            <Grid item xs={12} sm={6} md={3} style={{marginBottom: 15}} className="a-left p-t-10">
                                Status:
                                <FormControlLabel
                                    control={<GreenSwitch checked={actionObj.status} onChange={handleStatusChange}/>}
                                    label={<b>{(actionObj.status === true ? 'Active' : 'Inactive')}</b>}
                                    name="status"
                                    labelPlacement="start">
                                </FormControlLabel>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="input date">
                                    <i className="fas fa-calendar"/>
                                    <span>Automatic Closing Day</span>
                                    <input type="date" name="autoClosingDate"
                                           onChange={e => setActionObj({
                                               ...actionObj,
                                               autoClosingDate: e.target.value
                                           })}
                                           value={actionObj.autoClosingDate}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <div className="input">
                                    <span>Package</span>
                                    <input type="text" disabled name="packageName" value={accountPackageObj.name}/>
                                </div>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: 40}}>

                                <FormControl>
                                    <RadioGroup row value={actionObj.reason}
                                                onChange={handleReasonChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Activation Reason: </FormLabel>
                                        {
                                            activationReasons.map((reason) => (
                                                <FormControlLabel key={reason} value={reason}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  label={reason}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: 50}}>
                                <div className="input text">
                                    <span>Note</span>
                                    <textarea
                                        style={{maxHeight: 200}}
                                        value={actionObj.note}
                                        onChange={e => {
                                            setActionObj({...actionObj, note: e.target.value})
                                        }
                                        }/>
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </div>
                <div className="clearfix"/>
                <Grid container spacing={2}>
                    <Grid item xs={9}/>
                    <Grid item xs={3} style={{paddingRight: 15}}>
                        <button className="button m-t-40">SAVE AND UPDATE</button>
                    </Grid>
                </Grid>
                <div className="clearfix"/>
            </form>

        </>
    )
}

export default Status
