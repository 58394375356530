import {Link} from "react-router-dom";

export default function Transactions() {
    return (
        <>
            <div className="tabs has-3">
                <Link to="transaction" className="tab">Transaction</Link>
                <Link to="disbursement" className="tab">Disbursement</Link>
                <Link to="report" className="tab">Report</Link>
            </div>
            <div className="clearfix"/>
        </>
    )
}
