import AccountMenu from "../components/AccountMenu";
import AccountBadge from "../components/AccountBadge";
import {
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import ListHeader from "../components/ListHeader";
import AddBrand from "./AddBrand";
import BrandContext from "../contexts/BrandContext";
import {useParams} from "react-router-dom";
import AccountBrandInfo from "../components/AccountBrandInfo";

export default function Brands() {
    const brandsList = ['ID', 'Brand', 'Master Brand', 'Expire Date', 'Renewal', 'Type', 'Status', 'Distribution'];
    const {accountId} = useParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [entryCount, setEntryCount] = useState(10);
    const [isActive, setIsActive] = useState(false);

    const {getAccountBrands, accountBrands} = useContext(BrandContext);

    useEffect(() => {
        getAccountBrands(accountId);
    }, [])

    async function closePopup() {
        setIsActive(false);
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setEntryCount(event.target.value)
        setCurrentPage(0);
    };


    return (
        <>
            <AccountMenu/>
            <AccountBadge brands/>
            <div>
                {
                    accountBrands ? (
                        <TableContainer>
                            <Table className="data-table dataTable no-footer">
                                <ListHeader key={accountBrands.brandId} headList={brandsList}/>
                                <TableBody>
                                    {(
                                        entryCount > 0
                                            ? accountBrands.slice(currentPage * entryCount, currentPage * entryCount + entryCount) : accountBrands).map((
                                            accountBrand => <AccountBrandInfo key={accountBrand.brandId}
                                                                              value={{accountId, accountBrand}}/>
                                        )
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {accountBrands.length <= entryCount * (currentPage + 1)}
                                        <TablePagination count={accountBrands.length === -1 ? 0 : accountBrands.length}
                                                         onPageChange={handleChangePage}
                                                         page={currentPage}
                                                         rowsPerPage={entryCount}
                                                         onRowsPerPageChange={handleChangeRowsPerPage}/>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    ) : ""
                }
            </div>
            <div className="add-user open-popup m-t-20" data-popup="create">
                <button className="plus-button" onClick={e => setIsActive(true)}/>
                <p style={{
                    display: "inline-block",
                    position: "relative",
                    marginLeft: 15,
                    verticalAlign: "middle"
                }}> Add Brand</p>
            </div>
            <AddBrand closePopup={closePopup} isActive={isActive}
                      masterBrands={accountBrands.filter(accountBrand => accountBrand.type === "Master")}/>
        </>
    )
}
