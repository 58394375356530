import {Link, useParams} from "react-router-dom";
import {TableCell, TableRow} from "@material-ui/core";
import UserContext from "../contexts/UserContext";
import React, {useContext, useState} from "react";
import AccountUserDetails from "../pages/AccountUserDetails";
import {FaEye} from "react-icons/all";

function UserInfo({value}) {
    const {accountId} = useParams();

    const {getAccountUser} = useContext(UserContext)

    const [isActive, setIsActive] = useState(false);

    async function closePopup() {
        setIsActive(false);
    }

    const handleClick = async (event) => {
        event.preventDefault()
        setIsActive(true)
        await getAccountUser(accountId, value.accountUser.id)
    }

    return (<>
            {
                value ? (
                    <TableRow>
                        <TableCell><Link
                            onClick={handleClick}
                            className="detail-link"><i
                            className="fas fa-eye"/><i><FaEye/></i> {value.accountUser.id}</Link></TableCell>
                        <TableCell>{value.accountUser.firstName}</TableCell>
                        <TableCell>{value.accountUser.lastName}</TableCell>
                        <TableCell>{value.accountUser.email}</TableCell>
                        <TableCell>{value.accountUser.role}</TableCell>
                        <TableCell>{value.accountUser.status}</TableCell>
                    </TableRow>
                ) : ("")
            }
            <AccountUserDetails closePopup={closePopup} isActive={isActive} accountId={accountId}
                                user={value.accountUser}/>
        </>
    )
}

export default UserInfo;
