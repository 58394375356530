import React, {useContext, useEffect, useState} from "react";
import {FaTimes} from "react-icons/fa";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import AccountBrandActionsInfo from "../components/AccountBrandActionsInfo";
import BrandContext from "../contexts/BrandContext";
import {useParams} from "react-router-dom";

export default function AccountBrandDetails({closePopup, isActive, accountBrand, masterBrands, brandActions}) {

    const {accountId} = useParams();

    const types = ['Master', 'Sub-brand'];
    let [accountBrandObject, setAccountBrandObject] = useState({
        "brandId": "",
        "brandName": "",
        "type": "",
        "masterBrand": "",
        "expireDate": "",
        "autoRenewal": true
    });

    let [actionHistory, setActionHistory] = useState({
        "date": "",
        "action": "",
        "user": "",
        "detail": ""
    })

    const {updateAccountBrand} = useContext(BrandContext);

    useEffect(() => {
        setAccountBrandObject({
            "brandId": accountBrand.brandId,
            "brandName": accountBrand.brandName,
            "type": accountBrand.type,
            "masterBrand": accountBrand.masterBrand ? accountBrand.masterBrand.id : accountBrand.brandId,
            "expireDate": accountBrand.expireDate,
            "autoRenewal": accountBrand.autoRenewal
        })
        setActionHistory({
            "date": brandActions.date,
            "action": brandActions.action,
            "user": brandActions.user,
            "detail": brandActions.detail
        })
    }, [accountBrand])

    async function accountBrandUpdate(event) {
        event.preventDefault();
        await updateAccountBrand(accountId, accountBrandObject.brandId, accountBrandObject)
    }

    const [currentPage, setCurrentPage] = useState(0);
    const [entryCount, setEntryCount] = useState(10);
    const handleChangeRowsPerPage = (event) => {
        setEntryCount(event.target.value)
        setCurrentPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };


    return (<>
        <div className={`popup ${isActive ? "active" : ""} `}>
            <div className="tint"/>
            <div className="box active">
                <div className="title">BRAND DETAILS</div>
                <div className="cls">Close window<i onClick={closePopup}><FaTimes/></i></div>
                <form onSubmit={accountBrandUpdate}>
                    <Grid container spacing={2} className="p-60">
                        <Grid item xs={12}>
                            <div className="input">
                                <span>Brand Name</span>
                                <input type="text" name="firstName" value={accountBrandObject.brandName}
                                       onChange={e => setAccountBrandObject({
                                           ...accountBrandObject,
                                           brandName: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <select style={{width: '100%', paddingLeft: 20}} value={accountBrandObject.type}
                                    onChange={e => setAccountBrandObject({
                                        ...accountBrandObject,
                                        type: e.target.value
                                    })}>
                                <option disabled value=''>Brand Type</option>
                                {
                                    types.map(type =>
                                        <option key={types.indexOf(type)} value={types.indexOf(type)}>{type}</option>
                                    )
                                }
                            </select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <select style={{width: '100%', paddingLeft: 20}}
                                    value={accountBrandObject.masterBrand === 0 ? accountBrandObject.brandId : accountBrandObject.masterBrand}
                                    onChange={e => setAccountBrandObject({
                                        ...accountBrandObject,
                                        masterBrand: e.target.value
                                    })}>
                                <option disabled value={0}>Master Brand</option>
                                {
                                    masterBrands.map(masterBrand =>
                                        <option key={masterBrand.brandId}
                                                value={masterBrand.brandId}>{masterBrand.brandName}
                                        </option>
                                    )
                                }
                            </select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Expire Date</span>
                                <input type="date" name="agreementStartDate"
                                       onChange={e => setAccountBrandObject({
                                           ...accountBrandObject,
                                           expireDate: e.target.value
                                       })}
                                       value={accountBrandObject.expireDate}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div>
                                <input type="checkbox" name="closeAtEndDate"
                                       onChange={e => setAccountBrandObject({
                                           ...accountBrandObject,
                                           autoRenewal: e.target.checked
                                       })}
                                       value={accountBrandObject.autoRenewal}/>
                                <span>Auto Renewal</span>
                            </div>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={8}/>
                            <Grid item xs={4} style={{paddingRight: 15}}>
                                <button className="button m-t-40">UPDATE</button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <div>
                    <TableContainer>
                        <Table className="data-table dataTable no-footer">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>User</TableCell>
                                    <TableCell>Detail</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(
                                    entryCount > 0
                                        ? brandActions.slice(currentPage * entryCount, currentPage * entryCount + entryCount) : brandActions).map(
                                    actionHistory => <AccountBrandActionsInfo
                                        value={actionHistory}/>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {brandActions.length <= entryCount * (currentPage + 1)}
                                    <TablePagination
                                        count={brandActions.length === -1 ? 0 : brandActions.length}
                                        onPageChange={handleChangePage}
                                        page={currentPage}
                                        rowsPerPage={entryCount}
                                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <Grid container spacing={2} className="p-60">
                        <Grid item xs={8}/>
                        <Grid item xs={4} style={{paddingRight: 15}}>
                            <button className="button m-t-40">USER MANAGEMENT</button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </>)
}