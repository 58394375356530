import axios from "axios";
import {useEffect, useMemo, useState} from "react";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers: {'Authorization': `Bearer ${localStorage.token}`}
});

export const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);

    const requestHandler = request => {
        setCounter(counter => counter + 1)
        request.headers['Authorization'] = `Bearer ${localStorage.token}`
        return request;
    }
    const responseHandler = response => {
        setCounter(counter => counter - 1)
        if (response.status === 401) {
            window.location = "/login"
        }
        return response;
    }
    const errorHandler = error => {
        setCounter(counter => counter - 1)
        return Promise.reject(error);
    };


    const interceptors = useMemo(() => {
        return ({
            request: config => requestHandler(config),
            response: response => responseHandler(response),
            error: error => errorHandler(error)
        })
    }, []);

    useEffect(() => {
        const requestHandler = axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
        const responseHandler = axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);

        return () => {
            axiosInstance.interceptors.request.eject(requestHandler);
            axiosInstance.interceptors.response.eject(responseHandler);
        };
    }, [interceptors]);

    return [counter > 0];
}

export default axiosInstance;
