import AccountContext from "../contexts/AccountContext";
import {useState} from "react";
import axiosInstance from "../helpers/axiosInstance";

function AccountProvider(props) {
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState({});
    const [activationHistory, setActivationHistory] = useState([]);

    const getAccounts = async (filters) => {
        try {
            const result = await axiosInstance.post('/accounts', filters);
            setAccounts(result.data.body.accounts)
        } catch (err) {
            console.log("getAccounts ERROR >> ", err)
        }
    }

    const createNewAccount = async (details) => {
        try {
            const result = await axiosInstance.post('/account/new', details);
            setAccount(result.data.body);
        } catch (err) {
            console.log("createNewAccount ERROR >> ", err);
        }
    }

    const getAccount = async (accountId) => {
        try {
            const result = await axiosInstance.get(`/account/${accountId}`)
            setAccount(result.data.body)
        } catch (err) {
            console.log("getAccount ERROR >> ", err);
        }
    }

    const updateAccount = async (accountId, details) => {
        try {
            const result = await axiosInstance.put(`/account/${accountId}`, details);
            setAccount(result.data.body);
        } catch (err) {
            console.log("updateAccount ERROR >> ", err);
        }
    }

    const getAccountStatus = async (accountId) => {
        try {
            const result = await axiosInstance.get(`/account/${accountId}/status`);
            setActivationHistory(result.data.activationHistory)
        } catch (err) {
            console.log("getAccountStatus ERROR >> ", err);
        }
    }

    const postNewAction = async (accountId, details) => {
        try {
            const result = await axiosInstance.post(`/account/${accountId}/status`, details);
            setActivationHistory(result.data.activationHistory);
        } catch (err) {
            console.log("postNewAction ERROR >> ", err);
        }
    }

    const value = {
        getAccounts,
        accounts,
        createNewAccount,
        account,
        getAccount,
        updateAccount,
        getAccountStatus,
        activationHistory,
        postNewAction
    };

    return (
        <AccountContext.Provider value={value}>
            {props.children}
        </AccountContext.Provider>
    )
}

export default AccountProvider
