import {TableCell, TableRow} from "@material-ui/core";

function ActivationHistoryInfo(history) {

    return (<>
            <TableRow>
                <TableCell>{history.value.dateAction}</TableCell>
                <TableCell>{history.value.action}</TableCell>
                <TableCell>{history.value.actionBy}</TableCell>
                <TableCell> {history.value.reason}</TableCell>
                <TableCell> {history.value.note}</TableCell>
            </TableRow>
        </>
    )
}

export default ActivationHistoryInfo;
