import {FaTimes} from "react-icons/fa";
import React, {useContext, useState} from "react";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {useParams} from "react-router-dom";
import UserContext from "../contexts/UserContext";

export default function AccountUserDetails({closePopup, isActive, user}) {
    //
    // const [currentPage, setCurrentPage] = useState(0);
    // const [entryCount, setEntryCount] = useState(10);
    // const {getAccountUserStatus, accountUserStatus} = useContext(UserContext);

    // const handleChangeRowsPerPage = (event) => {
    //     setEntryCount(event.target.value)
    //     setCurrentPage(0);
    // };
    // const handleChangePage = (event, newPage) => {
    //     setCurrentPage(newPage);
    // };
    //
    // const getUserStatus = async () => {
    //     await getAccountUserStatus(accountId, user.id);
    // }

    return (
        <>
            <div className={`popup ${isActive ? "active" : ""} `}>
                <div className="tint"/>
                <div className="box active">
                    <div className="title">USER DETAILS</div>
                    <div className="cls">Close window<i onClick={closePopup}><FaTimes/></i></div>
                    <div className="p-60">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <b>{`${user.firstName} ${user.lastName}`}</b>
                            </Grid>
                            <Grid item xs={12} sm={6} className="a-right p-t-10">
                                Status: <b>{user.status} </b>
                                <Grid item md={1} className="status-box"
                                      style={{
                                          display: "inline-block",
                                          backgroundColor: user.status==='Active' ? '#4ADE79' : '#FF0000'
                                      }}/>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <TableContainer>
                            <Table className="data-table dataTable no-footer">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Person</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell>Not implemented</TableCell>
                                    <TableCell>Not implemented</TableCell>
                                    <TableCell>Not implemented</TableCell>
                                    <TableCell>Not implemented</TableCell>
                                </TableBody>
                                {/*<TableFooter>*/}
                                {/*    <TableRow>*/}
                                {/*        {accountUserStatus.length <= entryCount * (currentPage + 1)}*/}
                                {/*        <TablePagination*/}
                                {/*            count={accountUserStatus.length === -1 ? 0 : accountUserStatus.length}*/}
                                {/*            onPageChange={handleChangePage}*/}
                                {/*            page={currentPage}*/}
                                {/*            rowsPerPage={entryCount}*/}
                                {/*            onRowsPerPageChange={handleChangeRowsPerPage}/>*/}
                                {/*    </TableRow>*/}
                                {/*</TableFooter>*/}
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </>
    )
}