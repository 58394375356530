import {Link} from "react-router-dom";
import {Grid, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow} from "@material-ui/core";
import React, {useContext, useState} from "react";
import BrandContext from "../contexts/BrandContext";
import ListHeader from "../components/ListHeader";
import BrandInfo from "../components/BrandInfo";

export default function BrandsQuery() {

    const queryInitialObject = {
        "brandName": "",
        "closeDateStart": "",
        "closeDateEnd": "",
        "onlyActiveAgency": false,
        "onlyActiveBrands": false,
        "onlyPassiveBrands": false
    }
    const [queryObject, setQueryObject] = useState(queryInitialObject);
    const brandsQueryList = ['Client Name', 'Type', 'Package', 'Agency Status', 'Brand Name', 'Brand Type', 'Brand Status', 'Last Activate Date', 'Close Date', 'Distribution']

    const {getBrands, brandList} = useContext(BrandContext);

    async function displayBrands(event) {
        event.preventDefault();
        await getBrands(queryObject);
    }


    const [currentPage, setCurrentPage] = useState(0);
    const [entryCount, setEntryCount] = useState(10);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setEntryCount(event.target.value)
        setCurrentPage(0);
    };

    return (<>
        <div className="tabs">
            <Link to="client-management" className="tab">Client Management</Link>
            <Link to="brands-query" className="tab">Brands Query</Link>
        </div>
        <div className="clearfix"/>
        <div className="create-client"/>
        <form onSubmit={displayBrands}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div className="input">
                        <span>Company Name</span>
                        <input type="text" name="accountName" value={queryObject.brandName}
                               onChange={e => setQueryObject({
                                   ...queryObject,
                                   brandName: e.target.value
                               })}
                        />
                    </div>
                </Grid>
                <Grid item xs={6} className="a-right">
                    <div style={{display: 'inline-block'}} className="p-t-15">
                        <input type="checkbox" name="onlyActiveAgency"
                               onChange={e => setQueryObject({
                                   ...queryObject,
                                   onlyActiveAgency: e.target.checked
                               })}
                               value={queryObject.onlyActiveAgency}/>
                        <span>Only Active Agency</span>
                    </div>
                    <div className="v-line m-l-20"/>
                </Grid>
                <Grid item xs={2} className="p-l-0 m-r-0">
                    <div style={{paddingTop: 5}}>
                        <input type="checkbox" name="onlyActiveBrands"
                               onChange={e => setQueryObject({
                                   ...queryObject,
                                   onlyActiveBrands: e.target.checked
                               })}
                               value={queryObject.onlyActiveBrands}/>
                        <span>Active Brands</span>
                    </div>
                    <div style={{paddingTop: 5}}>
                        <input type="checkbox" name="onlyPassiveBrands"
                               onChange={e => setQueryObject({
                                   ...queryObject,
                                   brandsFilter: e.target.checked
                               })}
                               value={queryObject.onlyPassiveBrands}/>
                        <span>Passive Brands</span>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="input date">
                        <i className="fas fa-calendar"/>
                        <span>Close Date Start</span>
                        <input type="date" name="closeDateStart"
                               onChange={e => setQueryObject({
                                   ...queryObject,
                                   closeDateStart: e.target.value
                               })}
                               value={queryObject.closeDateStart}/>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="input date">
                        <i className="fas fa-calendar"/>
                        <span>Close Date Start</span>
                        <input type="date" name="closeDateEnd"
                               onChange={e => setQueryObject({
                                   ...queryObject,
                                   closeDateEnd: e.target.value
                               })}
                               value={queryObject.closeDateEnd}/>
                    </div>
                </Grid>
                <Grid item xs={9}/>
                <Grid item xs={3} className="pull-right">
                    <button className="button m-t-40">DISPLAY</button>
                </Grid>
                <Grid item style={{height: 60}} xs={12}/>
            </Grid>
        </form>
        {
            brandList ? (
                <TableContainer>
                    <Table className="data-table dataTable no-footer">
                        <ListHeader headList={brandsQueryList}/>
                        <TableBody>
                            {(
                                entryCount > 0
                                    ? brandList.slice(currentPage * entryCount, currentPage * entryCount + entryCount) : brandList).map((
                                    brand => <BrandInfo key={brand.brandId} value={brand}/>
                                )
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                {brandList.length <= entryCount * (currentPage + 1)}
                                <TablePagination count={brandList.length === -1 ? 0 : brandList.length}
                                                 onPageChange={handleChangePage}
                                                 page={currentPage}
                                                 rowsPerPage={entryCount}
                                                 onRowsPerPageChange={handleChangeRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            )
            : ""
        }
    </>)
}
