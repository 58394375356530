import {Link, useParams} from "react-router-dom";

export default function AccountMenu() {

    const {accountId} = useParams();

    return (<>
        <div className="top-bar">
            <Link to={`/account/${accountId}`} className="query-details">DETAILS</Link>
            <Link to={`/account/${accountId}/status`} className="query-status">STATUS</Link>
            <Link to={`/account/${accountId}/users`} className="query-users">USERS</Link>
            <Link to={`/account/${accountId}/brands`} className="query-brands">BRANDS</Link>
            <Link to={`/account/${accountId}/financial`} className="query-financial">FINANCIAL</Link>
            <Link to={`/account/${accountId}/credits`} className="query-credits">CREDITS</Link>
        </div>
    </>)
}
