import {FaTimes} from "react-icons/fa";
import React, {useState} from "react";
import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

export default function BrandDistribution({closePopup, isActive}) {
    const [brandDistributionObject, setBrandDistributionObject] = useState({})

    return (<>
        <div className={`popup ${isActive ? "active" : ""} `}>
            <div className="tint"/>
            <div className="box active">
                <div className="title">BRAND DISTRIBUTION</div>
                <div className="cls">Close window<i onClick={closePopup}><FaTimes/></i></div>
                <form>
                    <Grid container spacing={2} className="p-60">
                        <Grid item xs={12}>
                            <div className="input">
                                <span>Brand Name</span>
                                <input type="text" name="firstName" value={brandDistributionObject.brandName}
                                       onChange={e => setBrandDistributionObject({
                                           ...brandDistributionObject,
                                           brandName: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="input">
                                <span>Brand Type</span>
                                <input type="text" name="firstName" value={brandDistributionObject.type}
                                       onChange={e => setBrandDistributionObject({
                                           ...brandDistributionObject,
                                           type: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="input">
                                <span>Main Brand</span>
                                <input type="text" name="firstName" value={brandDistributionObject.mainBrand}
                                       onChange={e => setBrandDistributionObject({
                                           ...brandDistributionObject,
                                           mainBrand: e.target.value
                                       })}/>
                            </div>
                        </Grid>
                    </Grid>
                </form>
                <div>
                    <TableContainer>
                        <Table className="data-table dataTable no-footer">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Period</TableCell>
                                    <TableCell>Release</TableCell>
                                    <TableCell>Invitation</TableCell>
                                    <TableCell>Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableCell>Not implemented</TableCell>
                                <TableCell>Not implemented</TableCell>
                                <TableCell>Not implemented</TableCell>
                                <TableCell>Not implemented</TableCell>
                            </TableBody>
                            {/*<TableFooter>*/}
                            {/*    <TableRow>*/}
                            {/*        {accountUserStatus.length <= entryCount * (currentPage + 1)}*/}
                            {/*        <TablePagination*/}
                            {/*            count={accountUserStatus.length === -1 ? 0 : accountUserStatus.length}*/}
                            {/*            onPageChange={handleChangePage}*/}
                            {/*            page={currentPage}*/}
                            {/*            rowsPerPage={entryCount}*/}
                            {/*            onRowsPerPageChange={handleChangeRowsPerPage}/>*/}
                            {/*    </TableRow>*/}
                            {/*</TableFooter>*/}
                        </Table>
                    </TableContainer>
                </div>
            </div>

        </div>
    </>)
}