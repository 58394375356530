import AccountMenu from "../components/AccountMenu";
import AccountBadge from "../components/AccountBadge";

export default function Financial() {
    return (
        <div>
            <AccountMenu/>
            <AccountBadge/>
            <h1>FINANCIAL PAGE</h1>
        </div>
    )
}
