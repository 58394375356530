import {TableCell, TableRow} from "@material-ui/core";
import {Link, useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import BrandContext from "../contexts/BrandContext";
import {FaCog, FaEye} from "react-icons/all";
import AccountBrandDetails from "../pages/AccountBrandDetails";
import BrandDistribution from "../pages/BrandDistribution";

export default function AccountBrandInfo({value}) {
    const [isActive, setIsActive] = useState(false);
    const [isDistributionActive, setIsDistributionActive] = useState(false);
    const {accountId} = useParams()

    async function closePopup() {
        setIsActive(false);
    }

    async function closeDistributionPage() {
        setIsDistributionActive(false);
    }

    const {
        getAccountBrand,
        accountBrands,
        accountBrand,
        accountBrandActions,
        getAccountBrandDistribution,
        accountBrandDistribution
    } = useContext(BrandContext);

    const getAccountBrandDetails = async (event) => {
        event.preventDefault()
        setIsActive(true)
        await getAccountBrand(accountId, value.accountBrand.brandId)
    }

    const getBrandDistribution = async (event) => {
        event.preventDefault()
        setIsDistributionActive(true)
        await getAccountBrandDistribution(accountId, value.accountBrand.brandId)
    }

    return (<>
            <TableRow>
                <TableCell><Link
                    onClick={getAccountBrandDetails}
                    className="detail-link"><i><FaEye/></i> {value.accountBrand.brandId}</Link></TableCell>
                <TableCell>{value.accountBrand.brandName}</TableCell>
                <TableCell>{value.accountBrand.type === "Master" ? value.accountBrand.brandName : value.accountBrand.masterBrand.name}</TableCell>
                <TableCell>{value.accountBrand.expireDate}</TableCell>
                <TableCell>{value.accountBrand.autoRenewal ? "Auto" : "Manual"}</TableCell>
                <TableCell>{value.accountBrand.type}</TableCell>
                <TableCell>{value.accountBrand.status}</TableCell>
                <TableCell>{`${value.accountBrand.distribution.distribution.sent} + ${value.accountBrand.distribution.distribution.scheduled} `}
                    <Link onClick={getBrandDistribution} className="detail-link">
                        <i className="settings open-popup">
                            <FaCog style={{verticalAlign: "middle"}}/></i>
                    </Link>
                </TableCell>
            </TableRow>
            <AccountBrandDetails closePopup={closePopup} isActive={isActive} accountBrand={accountBrand}
                                 brandActions={accountBrandActions}
                                 masterBrands={accountBrands.filter(br => br.type === "Master")}/>
            <BrandDistribution closePopup={closeDistributionPage} isActive={isDistributionActive}
                               brandDistribution={accountBrandDistribution}/>

        </>
    )
}
