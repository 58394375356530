import React, {useContext, useState} from "react";
import {FaTimes} from "react-icons/fa";
import BrandContext from "../contexts/BrandContext";
import {useParams} from "react-router-dom";
import {Grid} from "@material-ui/core";

export default function AddBrand({closePopup, isActive, masterBrands}) {

    let initialAccountBrand = {
        "brandName": "",
        "type": "",
        "status": true,
        "masterBrandId": "",
        "expireDate": "",
        "autoRenewal": false
    };
    const {accountId} = useParams();
    const {addNewAccountBrand} = useContext(BrandContext);
    const [newAccountBrandObj, setNewAccountBrandObj] = useState(initialAccountBrand);
    const types = ['Master', 'Sub-brand'];

    async function submitNewAccountBrand(event) {
        event.preventDefault();
        await addNewAccountBrand(accountId, newAccountBrandObj);
        setNewAccountBrandObj(initialAccountBrand);
        await closePopup();
    }

    return (
        <>
            <div className={`popup ${isActive ? "active" : ""} `}>
                <div className="tint"/>
                <div className="box active">
                    <div className="title">CREATE BRAND</div>
                    <div className="cls">Close window<i onClick={closePopup}><FaTimes/></i></div>
                    <form onSubmit={submitNewAccountBrand}>
                        <Grid container spacing={2} className="p-60">
                            <Grid item xs={12}>
                                <div className="input">
                                    <span>Brand Name</span>
                                    <input type="text" name="firstName" value={newAccountBrandObj.brandName}
                                           onChange={e => setNewAccountBrandObj({
                                               ...newAccountBrandObj,
                                               brandName: e.target.value
                                           })}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <select style={{width: '100%', paddingLeft: 20}} value={newAccountBrandObj.country}
                                        onChange={e => setNewAccountBrandObj({
                                            ...newAccountBrandObj,
                                            type: e.target.value
                                        })}>
                                    <option disabled value=''>Brand Type</option>
                                    {
                                        types.map(type =>
                                            <option key={type} value={type}>{type}</option>
                                        )
                                    }
                                </select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <select style={{width: '100%', paddingLeft: 20}} value={newAccountBrandObj.country}
                                        onChange={e => setNewAccountBrandObj({
                                            ...newAccountBrandObj,
                                            country: e.target.value
                                        })}>
                                    <option disabled value=''>Master Brand</option>
                                    {
                                        masterBrands.map(masterBrand =>
                                            <option key={masterBrand.brandId}
                                                    value={masterBrand.brandId}>{masterBrand.brandName}</option>
                                        )
                                    }
                                </select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="input date">
                                    <i className="fas fa-calendar"/>
                                    <span>Expire Date</span>
                                    <input type="date" name="agreementStartDate"
                                           onChange={e => setNewAccountBrandObj({
                                               ...newAccountBrandObj,
                                               expireDate: e.target.value
                                           })}
                                           value={newAccountBrandObj.expireDate}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div>
                                    <input type="checkbox" name="closeAtEndDate"
                                           onChange={e => setNewAccountBrandObj({
                                               ...newAccountBrandObj,
                                               autoRenewal: e.target.checked
                                           })}
                                           value={newAccountBrandObj.autoRenewal}/>
                                    <span>Auto Renewal</span>
                                </div>
                            </Grid>
                            <Grid item xs={4} style={{marginTop: 40}}>
                                <button className="button">CREATE BRAND</button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    )

}
