import {useState} from "react";
import UserContext from "../contexts/UserContext";
import axiosInstance from "../helpers/axiosInstance";

function UserProvider(props) {

    const [accountUsers, setAccountUsers] = useState([]);
    const [accountUser, setAccountUser] = useState({});
    const [accountUserStatus, setAccountUserStatus] = useState([]);

    const getAccountUsers = async (accountId) => {
        try {
            const result = await axiosInstance.get(`/account/${accountId}/users`);
            if (result.data.stat === '404') {
                return result.data.message
            }
            setAccountUsers(result.data.body['users'])
            return result.data.body['users']
        } catch (err) {
            console.log('getAccountUsers ERROR >> ', err);
        }
    }

    const getAccountUser = async (accountId, userId) => {
        try {
            const result = await axiosInstance.get(`/account/${accountId}/user/${userId}`)
            setAccountUser(result.data.body.user)
            return result.data.body.user
        } catch (err) {
            console.log('getAccountUser ERROR >> ', err);
        }
    }

    const newAccountUser = async (accountId, newUserObj) => {
        try {
            const result = await axiosInstance.post(`/account/${accountId}/user/new`, newUserObj)
            setAccountUser(result.data.body.user);
            return result.data.body.user;
        } catch (err) {
            console.log('newAccountUser ERROR >> ', err);
        }
    }

    const getAccountUserStatus = async (accountId, userId) => {
        try {
            const result = await axiosInstance.get(`/account/${accountId}/user/${userId}/status`);
            setAccountUserStatus(result.data.body.user);
            return result.data.body.user;
        } catch (err) {
            console.log('getAccountUserStatus ERROR >> ', err);
        }
    }

    const value = {
        getAccountUsers,
        accountUsers,
        getAccountUser,
        accountUser,
        newAccountUser,
        getAccountUserStatus,
        accountUserStatus
    }

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider
