import AuthContext from "../contexts/AuthContext";
import {useState} from "react";
import axiosInstance from "../helpers/axiosInstance";

function AuthProvider(props) {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('');
    const [myraUser, setMyraUser] = useState({});
    const [parameters, setParameters] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const login = async (details) => {
        try {
            const result = await axiosInstance.post("/login",
                {
                    username: details.username,
                    password: details.password
                })
            localStorage.token = result.data['Cookie'];
            setIsLoggedIn(result.data['login'])
        } catch (err) {
            console.log("login Error >> ", err);
        }
    }

    const logout = () => {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('myraUser');
            setIsLoggedIn(false);
            setToken('');
        } catch (err) {
            console.log("logout ERROR >> ",err)// TODO ERROR MESSAGE COMPONENT?
        }
    }

    const getMyraUsers = async () => {
        try {
            const result = await axiosInstance.get("/me");
            setMyraUser(result.data.body.userInfo)
            localStorage.myraUser = JSON.stringify(result.data.body.userInfo)
        } catch (err) {
            console.log("getMyraUsers Error >> ", err);
        }
    }

    const getParameters = async () => {
        try {
            const result = await axiosInstance.get("/parameters")
            setParameters(result.data);
        } catch (err) {
            console.log("getParameters ERROR >> ", err)// TODO ERROR MESSAGE COMPONENT?
        }
    }

    const passwordReset = async (username) => {
        try {
            const result = await axiosInstance.post('/passwordreset', {username});
            console.log(result.data);
            return result.data;
        } catch (err) {
            console.log("passwordReset ERROR >>", err);// TODO ERROR MESSAGE COMPONENT?
        }
    }

    const value = {
        isLoggedIn,
        token,
        myraUser,
        login,
        logout,
        getMyraUsers,
        getParameters,
        parameters,
        passwordReset,
        setIsLoading,
        isLoading
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;

