import logo from "../assets/img/logo.svg";
import {useContext} from "react";
import {Link} from "react-router-dom";
import AuthContext from "../contexts/AuthContext";

function Header() {
    const {isLoggedIn, myraUser, logout} = useContext(AuthContext);

    function logoutClick(event) {
        event.preventDefault();
        logout();
    }

    return (<header>
            <a className="logo">
                <img src={logo} alt="logo"/>
            </a>
            {!isLoggedIn ?
                (
                    <>
                        <div className="acc">
                            Welcome. Please login!
                        </div>
                    </>)
                :
                (<>
                    <div className="acc">
                        Welcome, <Link to='/profile'><b>{myraUser.fullname}</b></Link>
                        <button onClick={logoutClick}>Logout</button>
                    </div>
                    <div className='clearfix'/>
                    <div className='top-bar clearfix'/>
                </>)}
        </header>
    )
}

export default Header;
