import AuthContext from "../contexts/AuthContext";
import {useContext} from "react";

function Profile() {
    const {myraUser} = useContext(AuthContext);

    return (
        <div>
            <div className='row m-b-90'>
                <div className='col-xs-4'>
                    <div className='input'>
                        <span>Username </span>
                        <input name='fullname' defaultValue={myraUser['fullname']} readOnly={true}/>
                    </div>
                </div>
                <div className='col-xs-4'>
                    <div className='input'>
                        <span>Title </span>
                        <input name='title' defaultValue={myraUser.title} readOnly={true}/>
                    </div>
                </div>
                <div className='col-xs-4'>
                    <div className='input'>
                        <span>E-mail </span>
                        <input name='email' defaultValue={myraUser.email} readOnly={true}/>
                    </div>
                </div>
                <div className='col-xs-4'>
                    <div className='input'>
                        <span>Region </span>
                        <input name='region' defaultValue={myraUser.region} readOnly={true}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
