import {TableCell, TableRow} from "@material-ui/core";

function BrandInfo(brand) {
    return (<>
            <TableRow>
                <TableCell>{brand.value.accountName}</TableCell>
                <TableCell>{brand.value.accountType}</TableCell>
                <TableCell>{brand.value.package}</TableCell>
                <TableCell>{brand.value['accountStatus']}</TableCell>
                <TableCell>{brand.value.brandName}</TableCell>
                <TableCell>{brand.value.type}</TableCell>
                <TableCell>{brand.value.status}</TableCell>
                <TableCell>{brand.value['lastActivateDate']}</TableCell>
                <TableCell>{brand.value['closeDate']}</TableCell>
                {/*<TableCell>{account.value.distribution}</TableCell>*/}
            </TableRow>
        </>
    )
}

export default BrandInfo;
