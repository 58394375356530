import {TableCell, TableRow} from "@material-ui/core";

export default function AccountBrandActionsInfo(actions) {
    return (<>
            <TableRow>
                <TableCell>{actions.value.date}</TableCell>
                <TableCell>{actions.value.action}</TableCell>
                <TableCell>{actions.value.user}</TableCell>
                <TableCell>{actions.value.detail}</TableCell>
            </TableRow>
        </>
    )
}
