import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, withStyles} from "@material-ui/core";
import AuthContext from "../contexts/AuthContext";
import React, {useContext, useState} from "react";
import AccountContext from "../contexts/AccountContext";

const GreenSwitch = withStyles({
    switchBase: {
        color: "#ff0000",
        '&$checked': {
            color: "#4ADE79",
        },
        '&$checked + $track': {
            backgroundColor: "#4ADE79",
        },
    },
    checked: {},
    track: {
        backgroundColor: "#ff0000"
    },
})(Switch);

function NewAccount() {
    const {myraUser, parameters} = useContext(AuthContext);
    const {createNewAccount} = useContext(AccountContext);

    const currencies = ['USD', 'EURO', 'TL'];
    const invoiceTypes = ['Local', 'Global'];
    const pricingModels = {'Fixed': 'Fixed', 'Perbrand': 'Per Brand'};
    const paymentTypes = {'monthly': "Monthly", "frontPayment": 'Front Payment'};
    const pricingInitialState = '';
    const pricingObjInitialState = {
        "pricing1": "",
        "pricing2": "",
        "pricing3": "",
        "pricing4": "",
        "pricing5": "",
        "pricing6": "",
    };

    const accountInitialState = {
        "accountName": "",
        "accountManagerId": "",
        "status": true,
        "accountType": "",
        //    contact
        "userName": "",
        "title": "",
        "mobilePhone": "",
        "email": "",
        "webSite": "",
        "address": "",
        "officePhone": "",
        "postalCode": "",
        "state": "",
        "country": "",
        //      agreement/pricing
        "package": "",
        "onlinePayment": false,
        "invoicingPage": false,
        "sendOnlyTest": false,
        "frontPaymentAmount": "",
        "currency": "",
        "invoicing": "",
        "paymentType": "",
        "pricingModel": "",
        "pricing": pricingInitialState,
        "closeAtEndDate": false,
        // legal-tax
        "companyFullName": "",
        "taxOffice": "",
        "taxNo": "",
        "taxPersonEmail": "",
        "invoiceAddress": "",
        "accountingCode": "",
        // accounting contact
        "accountingContactName": "",
        "accountingContactTitle": "",
        "accountingContactMobilePhone": "",
        "accountingContactEmail": "",
        "note": ""
    };

    const [newAccountObj, setNewAccountObj] = useState(accountInitialState);

    const handleStatusChange = (event) => {
        setNewAccountObj({...newAccountObj, [event.target.name]: event.target.checked});
    };

    const handleAccountTypeChange = (event) => {
        setNewAccountObj({...newAccountObj, "accountType": event.target.value, "package": ""});
    }

    function handlePackageTypeChange(event) {
        setNewAccountObj({...newAccountObj, "package": event.target.value})
    }

    function handleInvoicingChange(event) {
        setNewAccountObj({...newAccountObj, "invoicing": event.target.value})
    }

    function handleCurrencyChange(event) {
        setNewAccountObj({...newAccountObj, "currency": event.target.value})
    }

    function handlePricingModelChange(event) {
        event.target.value === 'Fixed' ? (
            setNewAccountObj({...newAccountObj, "pricingModel": event.target.value, "pricing": pricingInitialState})
        ) : (event.target.value === 'Perbrand' ? (setNewAccountObj({
                ...newAccountObj,
                "pricingModel": event.target.value,
                "pricing": pricingObjInitialState
            })) : (setNewAccountObj({
                ...newAccountObj,
                "pricingModel": '',
                "pricing": pricingInitialState
            }))
        )
    }

    async function createAccount(event) {
        event.preventDefault();
        await createNewAccount(newAccountObj);
    }

    let legalTaxData = (<div className="border p-30 p-t-60 p-b-40">
        <div className="title">LEGAL / TAX</div>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Company Full Name</span>
                    <input type="text" name="companyFullName" value={newAccountObj.companyFullName}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               companyFullName: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Tax Office</span>
                    <input type="text" name="taxOffice" value={newAccountObj.taxOffice}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               taxOffice: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Tax No</span>
                    <input type="text" name="taxNo" value={newAccountObj.taxNo}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               taxNo: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>E-mail</span>
                    <input type="text" name="taxPersonEmail" value={newAccountObj.taxPersonEmail}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               taxPersonEmail: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
                <div className="input">
                    <span>Invoice Address</span>
                    <input type="text" name="invoiceAddress" value={newAccountObj.invoiceAddress}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               invoiceAddress: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="input">
                    <span>Accounting Code</span>
                    <input type="text" name="accountingCode" value={newAccountObj.accountingCode}
                           onChange={e => setNewAccountObj({
                               ...newAccountObj,
                               accountingCode: e.target.value
                           })}
                    />
                </div>
            </Grid>
        </Grid>
    </div>)

    let accountingContactData = (<div className="border p-30 p-t-60 p-b-40 m-t-40">
        <div className="title">ACCOUNTING CONTACT</div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>Name</span>
                    <input type="text" name="accountingContactName" value={newAccountObj.accountingContactName}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               accountingContactName: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>Title</span>
                    <input type="text" name="accountingContactTitle" value={newAccountObj.accountingContactTitle}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               accountingContactTitle: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>Mobile Phone</span>
                    <input type="text" name="accountingContactMobilePhone"
                           value={newAccountObj.accountingContactMobilePhone}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               accountingContactMobilePhone: e.target.value
                           }))}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div className="input">
                    <span>E-mail</span>
                    <input type="text" name="accountingContactEmail" value={newAccountObj.accountingContactEmail}
                           onChange={e => setNewAccountObj(({
                               ...newAccountObj,
                               accountingContactEmail: e.target.value
                           }))}
                    />
                </div>
            </Grid>
        </Grid>
    </div>)

    let noteData = (<div className="border p-30 p-t-60 p-b-40 m-t-40">
        <div className="title">NOTE</div>
        <Grid container>
            <Grid item xs={12}>
                <div className="input text">
                                    <textarea
                                        style={{maxHeight: 200}}
                                        value={newAccountObj.note}
                                        onChange={e => {
                                            setNewAccountObj({...newAccountObj, note: e.target.value})
                                        }
                                        }/>
                </div>
            </Grid>
        </Grid>
    </div>)

    let agreement;
    let legalTax;
    let accountingContact;
    let note;

    switch (newAccountObj.accountType) {
        case '0':
            agreement = (<div className="query-form" data-form="agency">
                {/*AGREEMENT PRICING*/}
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">AGREEMENT / PRICING</div>
                        <Grid item xs={12} md={6}>
                            <div className="border m-0 p-15">
                                <FormControl>
                                    <RadioGroup row value={newAccountObj.package}
                                                onChange={handlePackageTypeChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Package: </FormLabel>
                                        {
                                            parameters.accountAndPackageTypes.map((account) =>
                                                account.type.toString() === newAccountObj.accountType ? (
                                                    account.packages.map((pkg) => (
                                                        <FormControlLabel key={pkg.type} value={pkg.type}
                                                                          control={<Radio size="small"
                                                                                          color="default"/>}
                                                                          label={pkg.name}/>
                                                    ))
                                                ) : "")
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginBottom: 15}} className="a-right p-t-20">
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="onlinePayment"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           onlinePayment: e.target.checked
                                       })}
                                       value={newAccountObj.onlinePayment}/>
                                <span>Online Payment</span>
                            </div>
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="invoicingPage"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           invoicingPage: e.target.checked
                                       })}
                                       value={newAccountObj.invoicingPage}/>
                                <span>Invoicing Page</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="sendOnlyTest"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           sendOnlyTest: e.target.checked
                                       })}
                                       value={newAccountObj.sendOnlyTest}/>
                                <span>Send Only Test Users</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input">
                                <span>Front Payment Amount</span>
                                <input type="text" name="frontPaymentAmount"
                                       value={newAccountObj.frontPaymentAmount}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           frontPaymentAmount: e.target.value
                                       }))}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={newAccountObj.currency}
                                                onChange={handleCurrencyChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Currency: </FormLabel>
                                        {
                                            currencies.map((currency) => (
                                                <FormControlLabel value={currency}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={currency}
                                                                  label={currency}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={newAccountObj.invoicing}
                                                onChange={handleInvoicingChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Invoicing: </FormLabel>
                                        {
                                            invoiceTypes.map((type) => (
                                                <FormControlLabel value={type}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={type}
                                                                  label={type}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup row value={newAccountObj.pricingModel}
                                            onChange={handlePricingModelChange}>
                                    {
                                        Object.keys(pricingModels).map((key, keyInd) =>
                                            <FormControlLabel style={{paddingRight: 30}}
                                                              value={key}
                                                              control={<Radio size="small"
                                                                              color="default"/>}
                                                              key={key}
                                                              label={pricingModels[key]}/>
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                            {/*    TODO Backendde object eklenecek, data kismi yapilacak. Agreement kismi! */}
                        </Grid>
                        {
                            newAccountObj.pricingModel === "Fixed" ? (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <div className="input">
                                            <span>Fixed</span>
                                            <input type="text" name="fixedPayment" value={newAccountObj.pricing}
                                                   onChange={e => setNewAccountObj({
                                                       ...newAccountObj,
                                                       pricing: e.target.value
                                                   })}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={8}/>
                                </>
                            ) : (newAccountObj.pricingModel === 'Perbrand' ? (
                                    <>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>1</span>
                                                <input type="text" name="pricing1"
                                                       value={newAccountObj.pricing.pricing1}
                                                       onChange={e => setNewAccountObj({
                                                           ...newAccountObj,
                                                           pricing: {
                                                               ...newAccountObj.pricing,
                                                               pricing1: e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>4-5</span>
                                                <input type="text" name="pricing2"
                                                       value={newAccountObj.pricing.pricing2}
                                                       onChange={e => setNewAccountObj({
                                                           ...newAccountObj,
                                                           pricing: {
                                                               ...newAccountObj.pricing,
                                                               pricing2: e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>6-10</span>
                                                <input type="text" name="pricing3"
                                                       value={newAccountObj.pricing.pricing3}
                                                       onChange={e => setNewAccountObj({
                                                           ...newAccountObj,
                                                           pricing: {
                                                               ...newAccountObj.pricing,
                                                               pricing3: e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>11-30</span>
                                                <input type="text" name="pricing4"
                                                       value={newAccountObj.pricing.pricing4}
                                                       onChange={e => setNewAccountObj({
                                                           ...newAccountObj,
                                                           pricing: {
                                                               ...newAccountObj.pricing,
                                                               pricing4: e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>31-50</span>
                                                <input type="text" name="pricing5"
                                                       value={newAccountObj.pricing.pricing5}
                                                       onChange={e => setNewAccountObj({
                                                           ...newAccountObj,
                                                           pricing: {
                                                               ...newAccountObj.pricing,
                                                               pricing5: e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <div className="input">
                                                <span>51+</span>
                                                <input type="text" name="pricing6"
                                                       value={newAccountObj.pricing.pricing6}
                                                       onChange={e => setNewAccountObj({
                                                           ...newAccountObj,
                                                           pricing: {
                                                               ...newAccountObj.pricing,
                                                               pricing6: e.target.value
                                                           }
                                                       })}
                                                />
                                            </div>
                                        </Grid>
                                    </>
                                ) : ''
                            )
                        }
                    </Grid>
                    <div className="col-xs-12 m-t-30 m-b-50">
                        <div className="h-line m-t-30"/>
                    </div>
                    <Grid item xs={12} sm={4} md={3}>
                        <button className="upload-button m-t-40">Upload Local Agreement</button>
                    </Grid>
                </div>
            </div>)
            legalTax = legalTaxData;
            accountingContact = null;
            note = noteData;
            break;
        case '1':
            agreement = (<div className="query-form" data-form="corporate">
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">AGREEMENT / PRICING</div>
                        <Grid item xs={12} md={6}>
                            <div className="border m-0 p-15">
                                <FormControl>
                                    <RadioGroup row value={newAccountObj.package}
                                                onChange={handlePackageTypeChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Package: </FormLabel>
                                        {
                                            parameters.accountAndPackageTypes.map((account) =>
                                                account.type.toString() === newAccountObj.accountType ? (
                                                    account.packages.map((pkg) => (
                                                        <FormControlLabel key={pkg.type} value={pkg.type}
                                                                          control={<Radio size="small"
                                                                                          color="default"/>}
                                                                          label={pkg.name}/>
                                                    ))
                                                ) : "")
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginBottom: 15}} className="a-right p-t-20">
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="onlinePayment"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           onlinePayment: e.target.checked
                                       })}
                                       value={newAccountObj.onlinePayment}/>
                                <span>Online Payment</span>
                            </div>
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="invoicingPage"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           invoicingPage: e.target.checked
                                       })}
                                       value={newAccountObj.invoicingPage}/>
                                <span>Invoicing Page</span>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="sendOnlyTest"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           sendOnlyTest: e.target.checked
                                       })}
                                       value={newAccountObj.sendOnlyTest}/>
                                <span>Send Only Test Users</span>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="closeAtEndDate"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           closeAtEndDate: e.target.checked
                                       })}
                                       value={newAccountObj.closeAtEndDate}/>
                                <span>Close at End Date</span>
                            </div>
                        </Grid>
                        <Grid item xs={6}/>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Agreement Start Date</span>
                                <input type="date" name="agreementStartDate"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           agreementStartDate: e.target.value
                                       })}
                                       value={newAccountObj.agreementStartDate}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Agreement End Date</span>
                                <input type="date" name="agreementEndDate"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           agreementEndDate: e.target.value
                                       })}
                                       value={newAccountObj.agreementEndDate}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={newAccountObj.invoicing}
                                                onChange={handleCurrencyChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Invoicing: </FormLabel>
                                        {
                                            invoiceTypes.map((type) => (
                                                <FormControlLabel value={type}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={type}
                                                                  label={type}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className="input">
                                <span>Free Amount</span>
                                <input type="text" name="freeAmount" value={newAccountObj.freeAmount}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           freeAmount: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div className="border m-0 p-10">
                                <FormControl>
                                    <RadioGroup row value={newAccountObj.currency}
                                                onChange={handleCurrencyChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Currency: </FormLabel>
                                        {
                                            currencies.map((currency) => (
                                                <FormControlLabel value={currency}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  key={currency}
                                                                  label={currency}/>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup row value={newAccountObj.pricingModel}
                                            onChange={handlePricingModelChange}>
                                    {
                                        Object.keys(paymentTypes).map((key, keyInd) =>
                                            <FormControlLabel style={{paddingRight: 30}}
                                                              value={paymentTypes[key]}
                                                              control={<Radio size="small"
                                                                              color="default"/>}
                                                              key={key}
                                                              label={paymentTypes[key]}/>
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                            {/*    TODO Backendde object eklenecek, data kismi yapilacak. Agreement kismi! */}
                        </Grid>
                    </Grid>
                    <div className="col-xs-12 m-t-30 m-b-50">
                        <div className="h-line m-t-30"/>
                    </div>
                    <Grid item xs={12} sm={4} md={3}>
                        <button className="upload-button m-t-40">Upload Local Agreement</button>
                    </Grid>
                </div>
            </div>)
            legalTax = legalTaxData;
            accountingContact = accountingContactData;
            note = noteData;
            break;
        case '2':
            agreement = (<div className="query-form" data-form="sponsored">
                {/*AGREEMENT PRICING*/}
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">AGREEMENT / PRICING</div>
                        <Grid item xs={12}>
                            <div className="border m-0 p-15">
                                <FormControl>
                                    <RadioGroup row value={newAccountObj.package}
                                                onChange={handlePackageTypeChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Package: </FormLabel>
                                        {
                                            parameters.accountAndPackageTypes.map((account) =>
                                                account.type.toString() === newAccountObj.accountType ? (
                                                    account.packages.map((pkg) => (
                                                        <FormControlLabel key={pkg.type} value={pkg.type}
                                                                          control={<Radio size="small"
                                                                                          color="default"/>}
                                                                          label={pkg.name}/>
                                                    ))
                                                ) : "")
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>

                        <Grid item xs={12} className="col-xs-12 m-t-30 m-b-30">
                            <div>
                                <input type="checkbox" name="sendOnlyTest"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           sendOnlyTest: e.target.checked
                                       })}
                                       value={newAccountObj.sendOnlyTest}/>
                                <span>Send Only Test Users</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Brand Closed Date Start</span>
                                <input type="date" name="brandClosedDateStart"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           brandClosedDateStart: e.target.value
                                       })}
                                       value={newAccountObj.brandClosedDateStart}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="input date">
                                <i className="fas fa-calendar"/>
                                <span>Brand Closed Date End</span>
                                <input type="date" name="brandClosedDateEnd"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           brandClosedDateEnd: e.target.value
                                       })}
                                       value={newAccountObj.brandClosedDateEnd}/>
                            </div>
                        </Grid>
                        {/*<Grid item xs={12} sm={6} md={4} className="col-xs-12 m-t-30 m-b-30">*/}
                        <Grid item xs={12} sm={6} md={4} style={{marginBottom: 15}} className="p-t-20">
                            <div style={{display: 'inline-block', padding: 15}}>
                                <input type="checkbox" name="passiveBrands"
                                       onChange={e => setNewAccountObj({
                                           ...newAccountObj,
                                           passiveBrands: e.target.checked
                                       })}
                                       value={newAccountObj.passiveBrands}/>
                                <span>Passive Brands</span>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="col-xs-12 m-t-30 m-b-50">
                        <div className="h-line m-t-30"/>
                    </div>
                    <Grid item xs={12} sm={4} md={3}>
                        <button className="upload-button m-t-40">Upload Local Agreement</button>
                    </Grid>
                </div>
            </div>)
            legalTax = legalTaxData;
            accountingContact = accountingContactData;
            note = noteData;
            break;
        default:
            agreement = null;
            legalTax = null;
            accountingContact = null;
            note = null;
            break;
    }

    return (
        <div>
            <form onSubmit={createAccount}>
                {/*Details Details*/}
                <div className="row p-15 m-t-30">
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item xs={12} md={4}>
                            <div className="input">
                                <span>Account Name</span>
                                <input type="text" name="accountName" value={newAccountObj.accountName}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           accountName: e.target.value
                                       }))}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <select style={{width: "100%", paddingLeft: 20}} value={newAccountObj.accountManagerId}
                                    onChange={e => setNewAccountObj({
                                        ...newAccountObj,
                                        accountManagerId: e.target.value
                                    })}>
                                <option disabled value=''>Select Account Manager</option>
                                {
                                    parameters.managers.map((manager) => manager.region === myraUser.region ? (
                                        <option key={manager.id} value={manager.id}>{manager.fullname}</option>) : ''
                                    )
                                }
                                <option value=''>None</option>
                            </select>
                            <i className="fas fa-angle-down"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{marginBottom: 15}} className="a-right p-t-10">
                            Status:
                            <FormControlLabel
                                control={<GreenSwitch checked={newAccountObj.status} onChange={handleStatusChange}/>}
                                label={<b>{(newAccountObj.status === true ? 'Active' : 'Inactive')}</b>}
                                name="status"
                                labelPlacement="start">
                            </FormControlLabel>
                        </Grid>
                    </Grid>
                </div>
                {/*Details Type*/}
                <div className="border query-checks p-15 m-t-30 m-b-40">
                    <Grid container>
                        <Grid item>
                            <FormControl>
                                <RadioGroup row value={newAccountObj.accountType} onChange={handleAccountTypeChange}>
                                    <FormLabel disabled
                                               style={{alignItems: "center", display: "flex", paddingRight: 15}}>Account
                                        Type: </FormLabel>
                                    {
                                        parameters.accountAndPackageTypes.map((accType) => (
                                                <FormControlLabel key={accType.type}
                                                                  value={accType.type}
                                                                  control={<Radio size="small" color="default"/>}
                                                                  label={accType.def}/>
                                            )
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                {/*CONTACT*/}
                <div className="border p-30 p-t-60 p-b-40 m-b-40">
                    <Grid container spacing={2}>
                        <div className="title">CONTACT</div>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>User Name</span>
                                <input type="text" name="userName" value={newAccountObj.userName}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           userName: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>Title</span>
                                <input type="text" name="title" value={newAccountObj.title}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           title: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>Mobile Phone</span>
                                <input type="text" name="mobilePhone" value={newAccountObj.mobilePhone}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           mobilePhone: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>E-Mail</span>
                                <input type="text" name="email" value={newAccountObj.email}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           email: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className="input">
                                <span>Web Site</span>
                                <input type="text" name="web" value={newAccountObj.webSite}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           webSite: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <div className="input">
                                <span>Address</span>
                                <input type="text" name="address" value={newAccountObj.address}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           address: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input">
                                <span>Office Phone</span>
                                <input type="text" name="officePhone" value={newAccountObj.officePhone}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           officePhone: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className="input" style={{whiteSpace: "nowrap", overflow: "hidden"}}>
                                <span>Post Code</span>
                                <input type="text" name="postalCode" value={newAccountObj.postalCode}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           postalCode: e.target.value
                                       }))}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            {/*TODO ask/add array*/}
                            <div className="input select">
                                <span>State</span>
                                <input type="text" name="state" value={newAccountObj.state}
                                       onChange={e => setNewAccountObj(({
                                           ...newAccountObj,
                                           state: e.target.value
                                       }))}/>
                                <i className="fas fa-angle-down"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <select style={{width: "100%", paddingLeft: 20}} value={newAccountObj.country}
                                    onChange={e => setNewAccountObj({
                                        ...newAccountObj,
                                        country: e.target.value
                                    })}>
                                <option disabled value=''>Select Country</option>
                                {
                                    Object.keys(parameters.timeZones).map((key, keyInd) =>
                                        <option key={key} value={key}>{parameters.timeZones[key].timezone}</option>
                                    )
                                }
                            </select>
                        </Grid>
                    </Grid>
                </div>
                <div className="query-forms">
                    {agreement}
                </div>
                <div className="query-forms">
                    <div className="query-form" data-form="agency">
                        {/*LEGAL TAX*/}
                        {legalTax}
                        {/*ACCOUNTING CONTACT*/}
                        {accountingContact}
                        {/*NOTE*/}
                        {note}
                    </div>
                </div>

                <div className="clearfix"/>
                <Grid container spacing={2}>
                    <Grid item xs={9}/>
                    <Grid item xs={3} style={{paddingRight: 15}}>
                        <button className="button m-t-40">SAVE AND UPDATE</button>
                    </Grid>
                </Grid>
                <div className="clearfix"/>
            </form>
        </div>
    )
}

export default NewAccount;
