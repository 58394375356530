import React, {useState, useContext} from 'react';
import AuthContext from "../contexts/AuthContext";
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";

function PasswordReset() {
    const [username, setUsername] = useState('')
    const {passwordReset} = useContext(AuthContext);

    function handleSubmit(event) {
        event.preventDefault();
        passwordReset(username);
        // history.push('/');
    }

    return (
        <div style={{width: "auto", position: 'absolute', left: '30%', top:'60%'}} className="border p-30 p-t-60 p-b-40">
            <div className="title">FORGOT PASSWORD</div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className='input'>
                            <span>Username (E-mail) </span>
                            <input name='username' onChange={e => setUsername(e.target.value)}
                                   value={username}/>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <button className='button' type='submit'>Reset Password</button>
                    </Grid>
                    <div style={{paddingTop:30}}>
                        <Link to="login">Back To Login</Link>
                    </div>
                </Grid>
            </form>
        </div>
    )
}

export default PasswordReset;
