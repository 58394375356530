import React from "react";
import {LinearProgress} from "@material-ui/core";
import {useAxiosLoader} from "../helpers/axiosInstance";

export default function Loader() {
    const [loading] = useAxiosLoader()

    return (
        <>
            <div className={`popup ${loading ? "active" : ""}`}>
                <div className="tint">
                    <div className="center">
                        <LinearProgress/>
                    </div>
                </div>
            </div>
        </>
    )
}
