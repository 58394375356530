import AccountMenu from "../components/AccountMenu";
import AccountBadge from "../components/AccountBadge";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../contexts/UserContext";
import UserInfo from "../components/UserInfo";
import {useParams} from "react-router-dom";
import AddUser from "./AddUser";

export default function Users() {

    const {getAccountUsers, accountUsers} = useContext(UserContext);
    const roles = {"User": "0", "Admin": "1"};
    const {accountId} = useParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [entryCount, setEntryCount] = useState(10);
    const [isActive, setIsActive] = useState(false);
    const [userFilter, setUserFilter] = useState('');

    async function getUsers() {
        await getAccountUsers(accountId)
    }

    async function closePopup() {
        setIsActive(false);
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setEntryCount(event.target.value)
        setCurrentPage(0);
    };

    useEffect(() => {
        userFilter ? getUsers().then(accountUsers.filter(accountUser => accountUser.role === userFilter)) :
            getUsers()
    }, [userFilter])

    return (
        <>
            <AccountMenu/>
            <AccountBadge/>
            <Grid container spacing={2} className="row m-t-90">
                <Grid item xs={12} sm={6} md={4}>
                    <select style={{width: "100%", paddingLeft: 20}} value={userFilter}
                            onChange={e => setUserFilter(e.target.value)}>
                        <option disabled value=''>Filter</option>
                        {
                            Object.keys(roles).map((key, idx) => <option key={roles[key]}
                                                                         value={key}>{key}</option>)
                        }
                        <option value=''>None</option>
                    </select>
                    <i className="fas fa-angle-down"/>
                </Grid>
            </Grid>
            <div>
                <TableContainer>
                    <Table className="data-table dataTable no-footer">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(
                                entryCount > 0
                                    ? accountUsers.slice(currentPage * entryCount, currentPage * entryCount + entryCount) : accountUsers).map((
                                    accountUser => <UserInfo key={accountUser.id} value={{accountId, accountUser}}/>
                                )
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                {accountUsers.length <= entryCount * (currentPage + 1)}
                                <TablePagination count={accountUsers.length === -1 ? 0 : accountUsers.length}
                                                 onPageChange={handleChangePage}
                                                 page={currentPage}
                                                 rowsPerPage={entryCount}
                                                 onRowsPerPageChange={handleChangeRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
            <div className="add-user open-popup m-t-20" data-popup="create">
                <button className="plus-button" onClick={e => setIsActive(true)}/>
                <p style={{
                    display: "inline-block",
                    position: "relative",
                    marginLeft: 15,
                    verticalAlign: "middle"
                }}> Add User</p>
            </div>
            <AddUser closePopup={closePopup} isActive={isActive}/>
        </>
    )
}
