import {FaTimes} from "react-icons/fa";
import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import React, {useContext, useState} from "react";
import UserContext from "../contexts/UserContext";
import {useParams} from "react-router-dom";

export default function AddUser({closePopup, isActive}) {
    const initialAccountUser={
        "firstName": "",
        "lastName": "",
        "email": "",
        "role": "",
        "status": true
    }
    const [newAccountUserObj, setNewAccountUserObj] = useState(initialAccountUser);
    const {accountId} = useParams();
    const roles = {"User": "0", "Admin": "1"};
    const {newAccountUser} = useContext(UserContext)

    function handleRoleChange(event) {
        setNewAccountUserObj({...newAccountUserObj, "role": event.target.value})
    }

    async function submitNewAccountUser(event) {
        event.preventDefault();
        await newAccountUser(accountId, newAccountUserObj)
        setNewAccountUserObj(initialAccountUser);
        await closePopup();
    }

    return (
        <>
            <div className={`popup ${isActive ? "active" : ""} `}>
                <div className="tint"/>
                <div className="box active">
                    <div className="title">CREATE USER</div>
                    <div className="cls">Close window<i onClick={closePopup}><FaTimes/></i></div>
                    <form onSubmit={submitNewAccountUser}>
                        <Grid container className="p-60">
                            <Grid item xs={12}>
                                <div className="input">
                                    <span>First Name</span>
                                    <input type="text" name="firstName" value={newAccountUserObj.firstName}
                                           onChange={e => setNewAccountUserObj({
                                               ...newAccountUserObj,
                                               firstName: e.target.value
                                           })}/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="input">
                                    <span>Last Name</span>
                                    <input type="text" name="firstName" value={newAccountUserObj.lastName}
                                           onChange={e => setNewAccountUserObj({
                                               ...newAccountUserObj,
                                               lastName: e.target.value
                                           })}/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="input">
                                    <span>E-Mail</span>
                                    <input type="text" name="firstName" value={newAccountUserObj.email}
                                           onChange={e => setNewAccountUserObj({
                                               ...newAccountUserObj,
                                               email: e.target.value
                                           })}/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <RadioGroup row value={newAccountUserObj.role}
                                                onChange={handleRoleChange}>
                                        <FormLabel disabled style={{
                                            alignItems: "center",
                                            display: "flex",
                                            paddingRight: 15
                                        }}>Role: </FormLabel>
                                        {
                                            Object.keys(roles).map((key, keyIdx) =>
                                                <FormControlLabel key={key} value={roles[key]}
                                                                  control={<Radio size="small"
                                                                                  color="default"/>}
                                                                  label={key}/>
                                            )
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} style={{marginTop: 40}}>
                                <button className="button">CREATE USER</button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    )
}
