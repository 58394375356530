import {Link} from "react-router-dom";
import {TableCell, TableRow} from "@material-ui/core";
import {useContext} from "react";
import AccountContext from "../contexts/AccountContext";

function AccountInfo(account) {
    const {getAccount} = useContext(AccountContext)

    const handleClick = async () => {
        await getAccount(account.value.id)
    }

    return (<>
            <TableRow>
                <TableCell><Link to={`/account/${account.value.id}`} onClick={handleClick} className="detail-link"><i
                    className="fas fa-eye"/>{account.value.accountName}</Link></TableCell>
                <TableCell>{account.value.type}</TableCell>
                <TableCell>{account.value.package}</TableCell>
                <TableCell>{account.value['payment']}</TableCell>
                <TableCell>{account.value.accountManager.fullName}</TableCell>
                <TableCell> 000 TL</TableCell>
            </TableRow>
        </>
    )
}

export default AccountInfo;
