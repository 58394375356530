import {Link} from "react-router-dom";

function NotFound() {
    return(
        <div>
            <h1>The page you were trying to access was not found!</h1>
            <Link to="/client-management">
                Go to Home page
            </Link>
        </div>
    )
}

export default NotFound;
