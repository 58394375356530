import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import AuthContext from "../contexts/AuthContext";

function PrivateRoute({children, ...rest}) {
    let {isLoggedIn} = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({location}) =>
                !isLoggedIn ? (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                ) : (children)
            }
        />
    );
}

export default PrivateRoute
