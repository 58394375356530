import '../assets/sass/style.scss';
import AuthContext from "../contexts/AuthContext";
import React, {useContext} from "react";
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from "react-router-dom";
import Transactions from "./Transactions";
import Login from "./Login";
import ClientManagement from "./ClientManagement";
import PrivateRoute from "./PrivateRoute";
import NotFound from "./NotFound";
import Profile from "./Profile";
import PasswordReset from "./PasswordReset";
import BrandsQuery from "../pages/BrandsQuery";
import AccountProvider from "../providers/AccountProvider";
import Header from "./Header";
import NewAccount from "../pages/NewAccount";
import Details from "../pages/Details";
import Status from "../pages/Status";
import Brands from "../pages/Brands";
import Financial from "../pages/Financial";
import Credits from "../pages/Credits";
import Users from "../pages/Users";
import UserProvider from "../providers/UserProvider";
import {FaDatabase, FaExchangeAlt} from "react-icons/fa";
import BrandProvider from "../providers/BrandProvider";

function Main() {
    const {isLoggedIn} = useContext(AuthContext);

    return (<Router>
            <div>
                <Header/>
                <main className='query-page' data-nav={false}>
                    <nav className='main' style={{display: isLoggedIn ? "block" : "none"}}>
                        {/*<div>*/}
                        <ul>
                            <li style={{listStyleType: "none"}}>
                                <Link to="/client-management"
                                      style={{fontWeight: "bold", textDecoration: "none"}}>
                                    <i><FaDatabase/></i> CLIENT MANAGEMENT</Link>
                            </li>
                            <li style={{listStyleType: "none"}}>
                                <Link to="/transactions" style={{fontWeight: "bold", textDecoration: "none"}}>
                                    <i><FaExchangeAlt/></i>TRANSACTIONS</Link>
                            </li>
                        </ul>
                        {/*</div>*/}
                    </nav>
                    <div className="container">
                        <Switch>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/">
                                <Redirect to="/client-management"/>
                            </Route>
                            <Route exact path="/password-reset">
                                <PasswordReset/>
                            </Route>
                            <PrivateRoute exact path="/client-management">
                                <AccountProvider>
                                    <ClientManagement/>
                                </AccountProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/brands-query">
                                <BrandProvider>
                                    <BrandsQuery/>
                                </BrandProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/transactions">
                                <Transactions/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/profile">
                                <Profile/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/new-account">
                                <AccountProvider>
                                    <NewAccount/>
                                </AccountProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/account/:accountId">
                                <AccountProvider>
                                    <Details/>
                                </AccountProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/account/:accountId/status">
                                <AccountProvider>
                                    <Status/>
                                </AccountProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/account/:accountId/users">
                                <AccountProvider>
                                    <UserProvider>
                                        <Users/>
                                    </UserProvider>
                                </AccountProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/account/:accountId/brands">
                                <AccountProvider>
                                    <BrandProvider>
                                        <Brands/>
                                    </BrandProvider>
                                </AccountProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/account/:accountId/financial">
                                <AccountProvider>
                                    <Financial/>
                                </AccountProvider>
                            </PrivateRoute>
                            <PrivateRoute exact path="/account/:accountId/credits">
                                <AccountProvider>
                                    <Credits/>
                                </AccountProvider>
                            </PrivateRoute>
                            <Route component={NotFound}/>
                        </Switch>
                    </div>
                </main>
            </div>
        </Router>
    )
}

export default Main;
