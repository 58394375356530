import {
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";

export default function ListHeader({headList}) {


    return (<>
            <TableHead>
                <TableRow>
                    {
                        headList.map(header => <TableCell>{header}</TableCell>)
                    }
                </TableRow>
            </TableHead>
        </>
    )
}
