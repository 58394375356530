import {useState} from "react";
import BrandContext from "../contexts/BrandContext";
import axiosInstance from "../helpers/axiosInstance";

function BrandProvider(props) {

    const [brandList, setBrandList] = useState([]);
    const [accountBrand, setAccountBrand] = useState({});
    const [accountBrands, setAccountBrands] = useState([]);
    const [accountBrandActions, setAccountBrandActions] = useState([]);
    // const [accountBrandDistribution, setAccountBrandDistribution] = useState([]);

    const getBrands = async (details) => {
        try {
            const result = await axiosInstance.post('/brands', details);
            if (result.data.statusCode === '404') {
                return result.data.message;
            }
            setBrandList(result.data.body.brands)
            return result.data.body.brands;
        } catch (err) {
            console.log("getBrands ERROR >> ", err);
        }
    }

    const getAccountBrands = async (accountId) => {
        try {
            const result = await axiosInstance.post(`/account/${accountId}/brands`);
            if (result.data.statusCode === '404') {
                return result.data.message;
            }
            setAccountBrands(result.data.body.brands)
            return result.data.body.brands
        } catch (err) {
            console.log("getAccountBrands ERROR >> ", err);
        }
    }

    const addNewAccountBrand = async (accountId, details) => {
        try {
            const result = await axiosInstance.post(`/account/${accountId}/brand/new`, details);
            if (result.data.statusCode === '404') {
                return result.data.message;
            }
            setBrandList(result.data.body.brands)
            return result.data.body.brands

        } catch (err) {
            console.log('addNewAccountBrand ERROR >> ', err);
        }
    }

    const getAccountBrand = async (accountId, brandId) => {
        try {
            const result = await axiosInstance.get(`/account/${accountId}/brand/${brandId}`);
            if (result.data.statusCode === '404') {
                return result.data.message;
            }
            setAccountBrand(result.data.body['brandDetails']);
            setAccountBrandActions(result.data.body.brandActions);
        } catch (err) {
            console.log('getAccountBrand ERROR >> ', err);
        }
    }

    const updateAccountBrand = async (accountId, brandId, details) => {
        try {
            const result = await axiosInstance.put(`/account/${accountId}/brand/${brandId}`, details);
            if (result.data.statusCode === '404') {
                return result.data.message;
            }
            setAccountBrand(result.data.body['brandDetails']);
            setAccountBrandActions(result.data.body.brandActions);
        } catch (err) {
            console.log('updateAccountBrand ERROR >> ', err);
        }
    }

    const getAccountBrandDistribution = async (accountId, brandId) => {
        try {
            const result = await axiosInstance.get(`/account/${accountId}/brand/${brandId}/pressreleases`);
            if (result.data.statusCode === '404') {
                return result.data.message;
            }
            console.log('distribution>>', result.data)
            // setAccountBrandDistribution(result.data.body.distributionPeriods)
        } catch (err) {
            console.log('addNewAccountBrand >>', err);
        }
    }


    const value = {
        getBrands,
        brandList,
        addNewAccountBrand,
        getAccountBrands,
        accountBrands,
        getAccountBrand,
        accountBrand,
        updateAccountBrand,
        accountBrandActions,
        getAccountBrandDistribution,
        // accountBrandDistribution
    }

    return (
        <BrandContext.Provider value={value}>
            {props.children}
        </BrandContext.Provider>
    )
}

export default BrandProvider
