import {useContext} from "react";
import AccountContext from "../contexts/AccountContext";
// import AuthContext from "../contexts/AuthContext";
import {Grid} from "@material-ui/core";
import AuthContext from "../contexts/AuthContext";

function AccountBadge(props) {

    const {account} = useContext(AccountContext)
    const {parameters} = useContext(AuthContext)

    const result = parameters['accountAndPackageTypes'].find(acc => acc.type === account.accountType);

    return (
        <div className="white-box p-50 m-b-40">
            <Grid container className="info">
                <Grid item xs={12} sm={6} md={8} className=" p-t-10">
                    <b>Account Name : </b>{account.accountName} <br/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="a-right p-t-10">
                    Status: <b>{account.status ? 'Active' : 'Inactive'} </b>
                    <Grid item md={1} className="status-box"
                          style={{
                              display: "inline-block",
                              backgroundColor: account.status ? '#4ADE79' : '#FF0000'
                          }}/>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 15}}>
                    <b>Account Type : </b> {result['def']}
                </Grid>
            </Grid>
            {
                (props && props.brands === true) ?
                    (
                        <Grid container className="brand">
                            <Grid item xs={12}>
                                <b>Total Brands :</b> {account['brandCounts'].total}
                            </Grid>
                            <Grid item xs={12}>
                                <b>Sub Brands :</b> {account['brandCounts'].sub}
                            </Grid>
                        </Grid>
                    ) : ""
            }
        </div>
    )
}

export default AccountBadge;
